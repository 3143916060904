import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const TrialBanner = ({ subscriptionStatus }) => {
  if (subscriptionStatus !== 'trialing' && subscriptionStatus !== 'past_due' && subscriptionStatus !== 'canceled') {
    return null;
  }

  const isPastDue = subscriptionStatus === 'past_due';
  const isCanceled = subscriptionStatus === 'canceled';

  return (
    <Box
      sx={{
        p: 0,
        backgroundColor: isPastDue ? '#d32f2f' : isCanceled ? '#d32f2f' : '#ffeb3b',
        color: isPastDue ? '#fff' : isCanceled ? '#fff' : '#000',
        textAlign: 'center',
        position: 'fixed',
        bottom: 0,
        width: '100%',
        zIndex: 1000,
        boxShadow: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 1,
      }}
    >
      <Typography variant="body1" sx={{ fontSize: '0.8rem'}}>
        {isPastDue
          ? 'Your trial has ended or your payment is past due. Please update your payment details.'
          : isCanceled
            ? 'Your subscription has been cancelled. Please goto account management to continue using LoanScout.'
            : 'You are currently in trial mode. Please sign up to continue using LoanScout.'}
      </Typography>
    </Box>
  );
};

export default TrialBanner;
