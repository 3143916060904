import React, { createContext, useState, useEffect, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useApi } from './api';

const SubscriptionContext = createContext();

export const SubscriptionProvider = ({ children }) => {
  const { isAuthenticated } = useAuth0();
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const api = useApi();

  useEffect(() => {
    const fetchSubscriptionData = async () => {
      if (isAuthenticated) {
        try {
          const response = await api.get('/api/subscription-status');
          setSubscriptionStatus(response.data.subscription_status);
        } catch (error) {
          console.error('Error fetching subscription data', error);
        }
      }
    };

    fetchSubscriptionData();
  }, [isAuthenticated, api]);

  return (
    <SubscriptionContext.Provider value={{ subscriptionStatus }}>
      {children}
    </SubscriptionContext.Provider>
  );
};

export const useSubscription = () => useContext(SubscriptionContext);
