import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Box, CssBaseline } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import AboutTab from './components/profile/AboutTab';
import Account from './pages/Account';
import FilingsTabScreen from './components/profile/FilingsTab';
import FollowedCompanies from './pages/Following';
import Home from './pages/Home';
import HomeTab from "./components/profile/HomeTab";
import LendingPreferencesPage from './pages/LendingPreferences';
import NotesTab from './components/profile/NotesTab';
import PlanTab from './components/profile/PlanTab';
import PeopleTabScreen from './components/profile/PeopleTab';
import Profile from './pages/Profile';
import Search from './pages/Search';
import Signin from './pages/Signin';
import { useSubscription } from './utilities/SubscriptionContext';
import Suggestions from './pages/Suggestions';
import TopBar from './components/TopBar';
import TrialBanner from "./components/TrialBanner";
import Head from "./components/Head";

const PrivateRoute = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) return <div>Loading...</div>;

  return isAuthenticated ? children : <Navigate to="/" />;
};

const App = () => {
  const { isAuthenticated } = useAuth0();
  const { subscriptionStatus } = useSubscription();


  return (
    <Router>
      <Head />
      <CssBaseline />
      {isAuthenticated && <TopBar />}
      <Box sx={{ display: 'flex' }}>
        <Box component="main" sx={{ flexGrow: 1, p: 0, mt: '64px' }}>
          <Routes>
            <Route path="/" element={isAuthenticated ? <Navigate to="/home" /> : <Signin />} />
            <Route path="/home" element={<PrivateRoute><Home /></PrivateRoute>} />
            <Route path="/search" element={<PrivateRoute><Search /></PrivateRoute>} />
            <Route path="/following" element={<PrivateRoute><FollowedCompanies /></PrivateRoute>} />
            <Route path="/suggestions" element={<PrivateRoute><Suggestions /></PrivateRoute>} />
            <Route path="/preferences" element={<PrivateRoute><LendingPreferencesPage /></PrivateRoute>} />
            <Route path="/profile/:orgId" element={<PrivateRoute><Profile /></PrivateRoute>}>
              <Route path="home" element={<PrivateRoute><HomeTab /></PrivateRoute>} />
              <Route path="about" element={<PrivateRoute><AboutTab /></PrivateRoute>} />
              <Route path="loans" element={<PrivateRoute><FilingsTabScreen /></PrivateRoute>} />
              <Route path="notes" element={<PrivateRoute><NotesTab /></PrivateRoute>} />
              <Route path="people" element={<PrivateRoute><PeopleTabScreen /></PrivateRoute>} />
              <Route path="plan" element={<PrivateRoute><PlanTab /></PrivateRoute>} />
            </Route>
            <Route path="/account" element={<PrivateRoute><Account /></PrivateRoute>} />
          </Routes>
        </Box>
      </Box>
      {isAuthenticated && <TrialBanner subscriptionStatus={subscriptionStatus} />}
    </Router>
  );
};

export default App;
