// src/components/PeopleTab.js
import React from 'react';
import { useOutletContext } from 'react-router-dom';
import {
  Card,
  CardContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Typography,
  Divider,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import parsePhoneNumber from 'libphonenumber-js'


const PeopleTabScreen = () => {
  const { contacts } = useOutletContext();

  return (
    <Card variant="outlined" sx={{ boxShadow: 3, borderRadius: 1, mb: 4 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Key People
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <List>
          {contacts.map((contact) => (
            <ListItem key={contact.id}>
              <ListItemAvatar>
                <Avatar>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={`${contact.first_name} ${contact.last_name}`}
                secondary={`Title: ${contact.title || 'N/A'} | Email: ${contact.email || 'N/A'} | Phone: ${parsePhoneNumber(contact.phone_1).formatNational() || 'N/A'} `}
              />
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

export default PeopleTabScreen;
