import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Outlet, NavLink, useLocation } from 'react-router-dom';
import {
  Container,
  Grid,
  Box,
  Typography,
  Avatar,
  Tabs,
  Tab,
  Card,
  CardContent,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  CircularProgress,
  IconButton,
} from '@mui/material';
import Business from '@mui/icons-material/Business';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useApi } from '../utilities/api';
import { PrettyOrgName } from '../utilities/Utilities';
import PersonIcon from "@mui/icons-material/Person";

const Profile = () => {
  const { orgId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const api = useApi();
  const [profile, setProfile] = useState({});
  const [records, setRecords] = useState([]);
  const [description, setDescription] = useState('');
  const [contacts, setContacts] = useState([]);
  const [recommendedCompanies, setRecommendedCompanies] = useState([]);
  const [recommendations, setRecommendations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [followingCounts, setFollowingCounts] = useState({});
  const [pppData, setPppData] = useState([]);
  const [followingAtMyCompany, setFollowingAtMyCompany] = useState([]);
  const [isFollowing, setIsFollowing] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const toggleFollowStatus = async () => {
    try {
      const url = `/api/profiles/${orgId}/follow`;
      const method = isFollowing ? 'delete' : 'post';

      // Attempt to update the server
      await api[method](url);

      // Update the UI if the API call succeeds
      setIsFollowing(!isFollowing);
    } catch (error) {
      console.error('Error changing follow status:', error);
    }
  };

  const fetchProfileData = async (organizationId) => {
    setIsLoading(true);
    try {
      const profileResponse = await api.get(`/api/profiles/${organizationId}`);
      const descriptionResponse = await api.get(`/api/profiles/${organizationId}/get-description`);
      const contactsResponse = await api.get(`api/profiles/${organizationId}/contacts`);

      setProfile(profileResponse.data);
      setDescription(descriptionResponse.data);
      setRecords(profileResponse.data.records || []);
      setContacts(contactsResponse.data);
      setFollowingCounts(profileResponse.data['following_counts'] || {});
      setFollowingAtMyCompany(profileResponse.data['following_at_my_company'] || []);
      setIsFollowing(profileResponse.data['is_following']);
      setPppData(profileResponse.data['ppp_data'] || []);
    } catch (error) {
      console.error('Error fetching profile data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchRecommendedCompanies = async () => {
    try {
      const response = await api.get(`/api/profiles/${orgId}/get-also-viewed-organizations`);
      setRecommendedCompanies(response.data);
    } catch (error) {
      console.error('Error fetching recommended companies:', error);
    }
  };

  const fetchRecommendations = async() => {
    try {
      const response = await api.get(`/api/recommendation-search?org_id=${orgId}&location=${profile.company_details.city}`);
      setRecommendations(response.data);
    } catch (error) {
      console.error('Error fetching recommended companies:', error);
    }
  }

  useEffect(() => {
    if (orgId) {
      fetchProfileData(orgId);
      fetchRecommendedCompanies();
    }
  }, [orgId]);

  useEffect(() => {
    if (orgId) {
      fetchRecommendations();
    }
  }, [description]);

  const tabPaths = [
    { label: 'Home', path: `/profile/${orgId}/home` },
    { label: 'About', path: `/profile/${orgId}/about` },
    { label: `Loans (${records.length})`, path: `/profile/${orgId}/loans` },
    { label: 'Notes', path: `/profile/${orgId}/notes` },
    { label: `People (${contacts.length})`, path: `/profile/${orgId}/people` },
    { label: 'Plan', path: `/profile/${orgId}/plan` },
  ];

  const activeTab = tabPaths.findIndex((tab) => location.pathname.includes(tab.path));

  const handleCompanyProfile = (companyId) => {
    navigate(`/profile/${companyId}/home`);
  };

  const renderRecommendedCompanies = () => (
    <Card variant="outlined" sx={{ boxShadow: 3, borderRadius: 1, mb: 2 }}>
      <CardContent>
        <Typography variant="body2" style={{ fontWeight: 'bold'}}gutterBottom>
          Companies People Also Viewed
        </Typography>
          <List>
            {recommendedCompanies.map((company) =>
              company.organization_info !== null && (
                <React.Fragment key={company.id}>
                  <ListItem
                    button
                    onClick={() => handleCompanyProfile(company.id)}
                    style={{ display: 'flex', alignItems: 'center', padding: '0.5rem .25rem' }}
                  >
                    <Avatar
                      src={company.logo_url}
                      alt={company.name}
                      style={{
                        width: 40,
                        height: 40,
                        marginRight: 8,
                        border: '2px solid #fff',
                        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                      }}
                      variant="rounded"
                    />
                    <Typography variant="body2" style={{ fontWeight: 600 }}>
                      {PrettyOrgName(company)}
                    </Typography>
                  </ListItem>
                  <Divider />
                </React.Fragment>
              )
            )}
          </List>
      </CardContent>
    </Card>
  );

  const renderFollowerStats = () => (
    <Card variant="outlined" sx={{ boxShadow: 3, borderRadius: 1, mb: 2, mt: -2 }}>
      <CardContent>
        <Typography variant="body2" style={{ fontWeight: 'bold' }} gutterBottom>
          Followed by
        </Typography>
        <List dense>
          { followingAtMyCompany.map((follow) => (
            <ListItem disablePadding>
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText primary={`${follow.user.first_name} ${follow.user.last_name}`} />
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );

  const renderRecommendations = () => (
    <Card variant="outlined" sx={{ boxShadow: 3, borderRadius: 1, mb: 2 }}>
      <CardContent>
        <Typography variant="body2" style={{ fontWeight: 'bold'}} gutterBottom>
          Recommendations
        </Typography>
          <List>
            {recommendations.map((company) =>
              company.organization_info !== null && (
                <React.Fragment key={company.id}>
                  <ListItem
                    button
                    onClick={() => handleCompanyProfile(company.id)}
                    style={{ display: 'flex', alignItems: 'center', padding: '0.5rem .25rem' }}
                  >
                    <Avatar
                      src={company.logo_url}
                      alt={company.name}
                      style={{
                        width: 40,
                        height: 40,
                        marginRight: 8,
                        border: '2px solid #fff',
                        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                      }}
                      variant="rounded"
                    />
                    <Typography variant="body2" style={{ fontWeight: 600 }}>
                      {PrettyOrgName(company)}
                    </Typography>
                  </ListItem>
                  <Divider />
                </React.Fragment>
              )
            )}
          </List>
      </CardContent>
    </Card>
  );

  return (
    <Container maxWidth="lg" sx={{ mt: 2 }}>
      <Grid container spacing={2}>
        {/* 3/4 Content Section */}
        <Grid item xs={12} md={9}>
          {/* Hero Header */}
          <Box
            sx={{
              background: 'linear-gradient(135deg, #f5f5f5, #ffffff)',
              color: 'black',
              p: 0,
              borderRadius: 1,
              boxShadow: 3,
              mb: 4,
              textAlign: 'left',
              position: 'relative',
            }}
          >
            <IconButton
              sx={{ position: 'absolute', top: 16, right: 16 }}
              aria-label="notifications"
            >
              <NotificationsIcon />
            </IconButton>
            <Grid container spacing={2} direction="row" alignItems="flex-start" sx={{ padding: 2}}>
              <Grid item>
                {profile?.company_details?.logo_url ? (
                  <Avatar
                    alt={profile.name}
                    src={profile.company_details.logo_url}
                    style={{ backgroundColor: 'white' }}
                    sx={{ width: 120, height: 120, mb: 1, p: 1, border: '1px solid lightgray' }}
                    variant="rounded"
                  />
                ) : (
                  <Avatar
                    alt={profile.name}
                    style={{ backgroundColor: 'white' }}
                    sx={{ width: 60, height: 60, mb: 1, p: 1, border: '1px solid lightgray', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    variant="rounded"
                  >
                    <Business sx={{ width: '80%', height: '80%', color: 'gray' }} />
                  </Avatar>
                )}
              </Grid>
              <Grid item>
                <Box>
                  <Typography variant="h6" style={{ fontWeight: 'bold' }}>{profile && profile.name ? PrettyOrgName(profile) : ''}</Typography>
                  <Box mt={1} mb={1}>
                    <Button
                      variant={isFollowing ? 'outlined' : 'contained'}
                      sx={{ textTransform: 'none' }}
                      size="small"
                      onClick={toggleFollowStatus}
                    >
                      {isFollowing ? 'Following' : '+ Follow'}
                    </Button>
                  </Box>
                </Box>

                <Typography variant="subtitle1">
                  {profile?.company_details?.city && profile.company_details.state
                    ? `${profile.company_details.city}, ${profile.company_details.state}`
                    : ''}
                  {profile?.company_details?.headcount ? ` • ${profile.company_details.headcount} employees` : ''}
                  {profile?.company_details?.founded_year ? ` • Founded ${profile.company_details.founded_year}` : ''}
                </Typography>
                <Typography variant="subtitle1">
                  {
                    profile?.company_details?.industry
                    || profile?.company_details?.modeled_general_industry
                    || profile?.company_details?.modeled_specific_industry
                    || ''
                  }</Typography>
              </Grid>
            </Grid>

            <Divider sx={{ mt: 0 }} />
            {/* Tab Navigation */}
            <Tabs value={activeTab} textColor="inherit" sx={{ backgroundColor: 'white', borderBottomLeftRadius: 2, borderBottomRightRadius: 2}}>
              {tabPaths.map((tab, index) => (
                <Tab
                  key={index}
                  component={NavLink}
                  to={tab.path}
                  label={tab.label}
                  sx={{
                    color: 'black',
                    fontWeight: 'bold',
                    textTransform: 'none',
                    p: 0,
                    '&.Mui-selected': { color: 'black', fontWeight: 'bold' },
                  }}
                />
              ))}
            </Tabs>
          </Box>

          {/* Tab Content */}
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Box>
              <Outlet context={{ profile, description, records, contacts, followingCounts, followingAtMyCompany, pppData }} />
            </Box>
          )}
        </Grid>

        {/* 1/4 Recommended Section */}
        <Grid item xs={12} md={3}>
          {renderFollowerStats()}
          {renderRecommendedCompanies()}
          {renderRecommendations()}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Profile;
