import React from 'react';
import { Card, CardContent, Typography, Divider, Button, useTheme, Box, Avatar, Chip, List, ListItem, ListItemText, ListItemAvatar } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import CircleIcon from '@mui/icons-material/Circle';
import HexagonIcon from '@mui/icons-material/Hexagon';
import EventIcon from '@mui/icons-material/Event';
import { PrettyOrgName, getLenderLogoUrl } from '../../utilities/Utilities';


const FeedItem = ({ item, borrowers, securedParties, amendments, isFilingActive, hasUpcomingAnniversary }) => {
  const theme = useTheme();
  const activeIcon = isFilingActive === 'Active' ?  <CircleIcon /> : <HexagonIcon />;
  const activeColor = isFilingActive === 'Active' ? 'info' : 'warning';

  return (
    <Card variant="outlined" sx={{
      width: '100%',
      margin: theme.spacing(0, 0, 2, 0),
      borderRadius: 2,
      borderColor: hasUpcomingAnniversary ? 'orange' : theme.palette.primary.main,
      borderWidth: hasUpcomingAnniversary ? 2 : 1
    }}>
      <CardContent sx={{ display: 'flex', flexDirection: 'column', padding: theme.spacing(2) }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', mb: 1, gap: 1 }}>
          <Chip
            icon={activeIcon}
            label={isFilingActive === 'Active' ? 'Active' : 'Inactive'}
            color={activeColor}
            size="small"
          />
          {hasUpcomingAnniversary && (
            <Chip
              icon={<EventIcon />}
              label="Upcoming Anniversary"
              color="info"
              size="small"
            />
          )}
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: theme.spacing(1) }}>
          <Avatar
            src={getLenderLogoUrl(securedParties[0].name)}
            sx={{ backgroundColor: theme.palette.primary.main, width: 40, height: 40, marginRight: theme.spacing(2) }}
          >
            <DescriptionIcon />
          </Avatar>

          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {PrettyOrgName(securedParties[0])}
          </Typography>
        </Box>
        <Typography variant="body2" color="textPrimary" sx={{ fontWeight: 'bold' }}>
          Secured Parties
        </Typography>
        {securedParties.map((lender, idx) => (
          <Box key={idx} mb={1}>
            <Typography variant="body2">
              {PrettyOrgName(lender)}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {`${lender.street_address}, ${lender.city}, ${lender.state} ${lender.zip}`}
            </Typography>
          </Box>
        ))}
        <Typography variant="body2" color="textPrimary" sx={{ fontWeight: 'bold' }}>
          Borrowers
        </Typography>
        {borrowers.map((borrower, idx) => (
          <Box key={idx} mb={1}>
            <Typography variant="body2">
              {borrower.name || `${borrower.first_name} ${borrower.last_name}`}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {`${borrower.street_address}, ${borrower.city}, ${borrower.state} ${borrower.zip}`}
            </Typography>
          </Box>
        ))}
        { item.collateral_data.length > 0 && (
          <>
            <Typography variant="body2" color="textPrimary" sx={{ fontWeight: 'bold' }}>
              Collateral Description
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {item.collateral_data}
            </Typography>
          </>
        )}
        <Divider sx={{ my: 2 }} />
        <Typography variant="subtitle1">Amendment History</Typography>
        <List>{amendments}</List>
      </CardContent>
    </Card>
  );
};

export default FeedItem;
