import React from 'react';
import { Card, CardContent, Typography, Divider, Button, useTheme, Box, Avatar, Chip, List, ListItem, ListItemText, ListItemAvatar } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import FaceIcon from '@mui/icons-material/Face';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import EventIcon from '@mui/icons-material/Event';
import UpdateIcon from '@mui/icons-material/Update';
import { useNavigate } from 'react-router-dom';
import {format, parse, formatDistanceToNow, parseISO} from 'date-fns';
import { expandRecordCode, PrettyOrgName } from '../utilities/Utilities';

const FeedItem = ({ item }) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const formatDate = (dateString) => format(parse(dateString, 'yyyyMMdd', new Date()), 'MMM d, yyyy');

  const handleCompanyProfile = () => {
    navigate(`/profile/${item.borrowers[0].id}/home`);
  };

  const getAmendments = (amendments) =>
    amendments
      .sort((a, b) => b.filing_date.localeCompare(a.filing_date))
      .map((amendment, index) => (
        <ListItem key={index} disablePadding>
          <ListItemAvatar>
            <Avatar>
              <RadioButtonUncheckedIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={`${expandRecordCode(amendment.filing_type)} - ${formatDate(amendment.filing_date)}`}
            secondary={`Filed ${formatDistanceToNow(parseISO(amendment.filing_date), {addSuffix: true})}`}
          />
        </ListItem>
      ));

  const reasonIcon = item.reason === 'anniversary' ? <EventIcon /> : <UpdateIcon />;
  const reasonColor = item.reason === 'anniversary' ? 'info' : 'success';

  return (
    <Card variant="outlined" sx={{
      width: '100%',
      margin: theme.spacing(0, 0, 2, 0),
      borderRadius: 2,
      borderColor: theme.palette.primary.main,
    }}>
      <CardContent sx={{ display: 'flex', flexDirection: 'column', padding: theme.spacing(2) }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', mb: 1, gap: 1 }}>
          <Chip
            icon={reasonIcon}
            label={item.reason === 'anniversary' ? 'Upcoming Anniversary' : 'Recent Activity'}
            color={reasonColor}
            size="small"
          />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: theme.spacing(1) }}>
          <Avatar
            sx={{ backgroundColor: theme.palette.primary.main, width: 40, height: 40, marginRight: theme.spacing(2) }}
          >
            <DescriptionIcon />
          </Avatar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, cursor: 'pointer' }} onClick={handleCompanyProfile}>
            {PrettyOrgName(item.borrowers[0])}
          </Typography>
        </Box>
        <Typography variant="body2" color="textPrimary" sx={{ fontWeight: 'bold' }}>
          Borrowers
        </Typography>
        {item.borrowers.map((borrower, idx) => (
          <Box key={idx} mb={1}>
            <Typography variant="body2">
              {PrettyOrgName(borrower) || `${borrower.first_name} ${borrower.last_name}`}
            </Typography>
          </Box>
        ))}
        <Box sx={{ backgroundColor: theme.palette.background.default, padding: 0, borderRadius: 1, mb: 1 }}>
          <Typography variant="body2" color="textPrimary" sx={{ fontWeight: 'bold' }}>
            Secured Parties
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {item.secured_parties.map(p => p.name).join(', ')}
          </Typography>
        </Box>
        <Typography variant="body2" color="textPrimary" sx={{ fontWeight: 'bold' }}>
          Collateral Description
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {item.collateral_data}
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Typography variant="subtitle1">Amendment History</Typography>
        <List>{getAmendments(item.amendment_files)}</List>
      </CardContent>
      <Divider />
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: theme.spacing(1) }}>
        <Typography variant="body2" color="textSecondary">
          {item.borrowers[0]?.follower_count} follower{item.borrowers[0]?.follower_count !== 1 ? 's' : ''}
        </Typography>
        <Button onClick={handleCompanyProfile} variant="text" size="small" sx={{ textTransform: 'none' }}>
          View Details
        </Button>
      </Box>
    </Card>
  );
};

export default FeedItem;
