import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Container, Typography, Box } from '@mui/material';
import { styled } from '@mui/system';

const SigninContainer = styled(Container)({
  textAlign: 'center',
  marginTop: '20vh',
  backgroundColor: '#ffffff',
  padding: '2rem',
  width: '400px',
  borderRadius: '8px',
  // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  boxShadow: '0 12px 40px rgba(0,0,0,.12)',
});

const SigninButton = styled(Button)({
  marginTop: '2rem',
  borderRadius: '4px',
  backgroundColor: '#007bff',
  '&:hover': {
    backgroundColor: '#0056b3',
  },
  width: '100%',
  textTransform: 'none',
  height: '2rem',
});

const Background = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  // background: 'linear-gradient(135deg, #007bff 0%, #424242 100%)',
  backgroundColor: '#ffffff',
  zIndex: -1,
});

const Signin = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Box height="100%"  justifyContent="center">
      <Background />
      <SigninContainer maxWidth="xs">
        <img
          src="https://loanscout-public.s3.us-west-2.amazonaws.com/LoanScout-7.png"
          alt="LoanScout Logo"
          style={{ width: '80%', marginBottom: '0rem' }}
        />
        <Typography variant="body1" gutterBottom style={{ color: '#282828', fontWeight: 'bold'}}>
          Empowered Lending Starts Here.
        </Typography>
        <Typography variant="body1" gutterBottom style={{ color: '#424242', marginBottom: '2rem' }}>
          Sign in to get started.
        </Typography>
        <SigninButton
          variant="contained"
          onClick={() =>
            loginWithRedirect({
              audience: 'my.audience.com',
              scope: 'openid profile email offline_access',
            })
          }
        >
          Continue
        </SigninButton>
      </SigninContainer>
    </Box>
  );
};

export default Signin;
