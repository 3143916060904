import React, { useEffect, useState } from 'react';
import { useOutletContext, Link as RouterLink } from 'react-router-dom';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Divider,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
} from '@mui/material';
import LoanTimelineChart from './LoanTimelineChart';
import BankIcon from '@mui/icons-material/AccountBalance';
import PersonIcon from '@mui/icons-material/Person';
import NoteIcon from '@mui/icons-material/Note';
import { isFilingActive, getLenderLogoUrl } from '../../utilities/Utilities';
import parsePhoneNumber from "libphonenumber-js";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";

const HomeTabScreen = () => {
  const { profile, description, records, contacts, pppData } = useOutletContext();
  const [lenders, setLenders] = useState([]);
  const [newLoans, setNewLoans] = useState(0);

  const getCurrentLenders = (records) => {
    const activeLoans = records.filter((record) => isFilingActive(record) === 'Active');
    const lenderMap = new Map();

    activeLoans.forEach((record) => {
      record.organization_files
        .filter((file) => file.record_code === 4)
        .forEach((lender) => {
          const normalizedLenderName = lender.name.toLowerCase();
          if (!lenderMap.has(normalizedLenderName)) {
            const formattedLenderName = lender.name
              .split(' ')
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
              .join(' ');
            const logoUrl = getLenderLogoUrl(formattedLenderName);

            lenderMap.set(normalizedLenderName, {
              name: formattedLenderName,
              count: 1,
              logoUrl,
            });
          } else {
            lenderMap.get(normalizedLenderName).count++;
          }
        });
    });

    return Array.from(lenderMap.values());
  };

  const newLoansLast12Months = (records) => {
    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

    let newLoansCount = 0;
    for (const record of records) {
      for (const amendment of record.amendment_files) {
        if (amendment.filing_type === 'MR') {
          const year = parseInt(amendment.filing_date.substring(0, 4), 10);
          const month = parseInt(amendment.filing_date.substring(4, 6), 10) - 1;
          const day = parseInt(amendment.filing_date.substring(6), 10);
          const amendmentDate = new Date(year, month, day);

          if (amendmentDate > oneYearAgo) {
            newLoansCount++;
            break;
          }
        }
      }
    }
    return newLoansCount;
  };

  const formatDescription = (text) => {
    // Return the first 35 words of the description if text is not null
    if (text) {
      return text.split(" ").slice(0, 35).join(" ") + '...';
    } else {
      return '';
    }
  };

  useEffect(() => {
    const currentLenders = getCurrentLenders(records);
    setLenders(currentLenders);
    const newLoansCount = newLoansLast12Months(records);
    setNewLoans(newLoansCount);
  }, [records]);

  return (
    <Grid container spacing={4}>
      {/* About Summary */}
      <Grid item xs={12}>
        <Card variant="outlined" sx={{ boxShadow: 3, borderRadius: 1 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              About
            </Typography>
              {pppData && pppData.length > 0 && (
                <>
                  <List>
                    {pppData.map((data, index) => (
                      <ListItem key={index}>
                        <ListItemAvatar>
                          <Avatar>
                            <BusinessCenterIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={`Business Type: ${data.BusinessType}`}
                          secondary={`Gender: ${data.Gender}, Veteran: ${data.Veteran}, NonProfit: ${data.NonProfit || 'No'}, NAICS: ${data.NAICSCode}`}
                        />
                      </ListItem>
                    ))}
                  </List>
                  <Divider sx={{ mb: 2 }} />
                  </>
              )}
            <Typography variant="inherit" mb={2}>
              {formatDescription(description)}
            </Typography>
            <Button component={RouterLink} to={`/profile/${profile.id}/about`} variant="contained" size="small">
              Learn More
            </Button>
          </CardContent>
        </Card>
      </Grid>

      {/*<Grid item xs={12}>*/}
      {/*  <Card variant="outlined" sx={{ boxShadow: 3, borderRadius: 1 }}>*/}
      {/*    <CardContent>*/}
      {/*      <Typography variant="h6" gutterBottom>*/}
      {/*        PPP Loan Data*/}
      {/*      </Typography>*/}
      {/*      <Divider sx={{ mb: 2 }} />*/}
      {/*      {pppData && pppData.length > 0 ? (*/}
      {/*        <List>*/}
      {/*          {pppData.map((data, index) => (*/}
      {/*            <ListItem key={index}>*/}
      {/*              <ListItemAvatar>*/}
      {/*                <Avatar>*/}
      {/*                  <BusinessCenterIcon />*/}
      {/*                </Avatar>*/}
      {/*              </ListItemAvatar>*/}
      {/*              <ListItemText*/}
      {/*                primary={`Business Type: ${data.BusinessType}`}*/}
      {/*                secondary={`Gender: ${data.Gender}, Veteran: ${data.Veteran}, NonProfit: ${data.NonProfit || 'No'}, NAICS: ${data.NAICSCode}`}*/}
      {/*              />*/}
      {/*            </ListItem>*/}
      {/*          ))}*/}
      {/*        </List>*/}
      {/*      ) : (*/}
      {/*        <Typography variant="body2">*/}
      {/*          No PPP Loan Data.*/}
      {/*        </Typography>*/}
      {/*      )}*/}
      {/*    </CardContent>*/}
      {/*  </Card>*/}
      {/*</Grid>*/}

      {/* Recent Loans Summary */}
      <Grid item xs={12}>
        <LoanTimelineChart records={records} orgId={profile.id}/>
        {/*<Card variant="outlined" sx={{ boxShadow: 3, borderRadius: 1 }}>*/}
        {/*  <CardContent>*/}
        {/*    <Typography variant="h6" gutterBottom>*/}
        {/*      Recent Loans*/}
        {/*    </Typography>*/}
        {/*    <Divider sx={{ mb: 2 }} />*/}
        {/*    <Typography variant="body2" mb={2}>*/}
        {/*      {newLoans} New Loans in the Last 12 Months*/}
        {/*    </Typography>*/}
        {/*    <Button component={RouterLink} to={`/profile/${profile.id}/loans`} variant="contained" size="small">*/}
        {/*      View All Loans*/}
        {/*    </Button>*/}
        {/*  </CardContent>*/}
        {/*</Card>*/}
      </Grid>

      {/* Current Lenders */}
      <Grid item xs={12}>
        <Card variant="outlined" sx={{ boxShadow: 3, borderRadius: 1 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Current Lenders
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <List>
              {lenders.map((lender, index) => (
                <ListItem key={index}>
                  <ListItemAvatar>
                    <Avatar>
                      {lender.logoUrl ? (
                        <img src={lender.logoUrl} alt={lender.name} width="24" height="24" />
                      ) : (
                        <BankIcon />
                      )}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${lender.name}: ${lender.count} loan${lender.count > 1 ? 's' : ''}`}
                  />
                </ListItem>
              ))}
            </List>
            <Button component={RouterLink} to={`/profile/${profile.id}/loans`} variant="contained" size="small">
              View All Loans
            </Button>
          </CardContent>
        </Card>
      </Grid>

      {/* Key People Summary */}
      <Grid item xs={12}>
        <Card variant="outlined" sx={{ boxShadow: 3, borderRadius: 1 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Key People
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <List>
              {contacts.slice(0, 3).map((contact) => (
                <ListItem key={contact.id}>
                  <ListItemAvatar>
                    <Avatar>
                      <PersonIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${contact.first_name} ${contact.last_name}`}
                    secondary={`Title: ${contact.title || 'N/A'} | Email: ${contact.email || 'N/A'} | Phone: ${parsePhoneNumber(contact.phone_1).formatNational() || 'N/A'} `}
                  />
                </ListItem>
              ))}
            </List>
            <Button component={RouterLink} to={`/profile/${profile.id}/people`} variant="contained" size="small">
              View All People
            </Button>
          </CardContent>
        </Card>
      </Grid>

      {/* Notes Summary */}
      <Grid item xs={12}>
        <Card variant="outlined" sx={{ boxShadow: 3, borderRadius: 1 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Recent Notes
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <List>
              {profile.notes && profile.notes.slice(0, 3).map((note, index) => (
                <ListItem key={index}>
                  <ListItemAvatar>
                    <Avatar>
                      <NoteIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={`Note ${index + 1}`} secondary={note} />
                </ListItem>
              ))}
            </List>
            <Button component={RouterLink} to={`/profile/${profile.id}/notes`} variant="contained" size="small">
              View All Notes
            </Button>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default HomeTabScreen;
