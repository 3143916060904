import React from 'react';

import { ScatterChart, Scatter, XAxis, YAxis, Tooltip, CartesianGrid, ReferenceLine, Text } from 'recharts';
import { format, parse, addYears, subYears, isValid, startOfYear } from 'date-fns';
import {
  Button,
  Card,
  CardContent,
  Divider,
  Typography
} from "@mui/material";
import { PrettyOrgName, expandRecordCode, isFilingActive } from "../../utilities/Utilities";
import {Link as RouterLink} from "react-router-dom";

const preprocessData = (records) => {
  const today = new Date();
  const yearsToAdd = 5;

  return records.map((record, index) => {
    const initialFiling = record.amendment_files.find(file => file.filing_type === 'MR');
    const continuations = record.amendment_files.filter(file => file.filing_type === 'C');
    const terminations = record.amendment_files.filter(file => file.filing_type === 'T');
    const allFilings = [initialFiling, ...continuations, ...terminations].filter(Boolean);

    const latestFiling = allFilings.sort((a, b) => parse(b.filing_date, 'yyyyMMdd', new Date()) - parse(a.filing_date, 'yyyyMMdd', new Date()))[0];

    const anniversaries = [];
    if (latestFiling && latestFiling.filing_type !== 'T') {
      const latestFilingDate = parse(latestFiling.filing_date, 'yyyyMMdd', new Date());
      if (isValid(latestFilingDate)) {
        for (let i = 1; i <= yearsToAdd; i++) {
          const anniversaryDate = addYears(latestFilingDate, i);
          anniversaries.push({ date: anniversaryDate, type: 'Anniversary', loanIndex: index + 1 });
        }
      }
    }

    return [
      ...allFilings.map(filing => ({ date: parse(filing.filing_date, 'yyyyMMdd', new Date()), type: filing.filing_type, loanIndex: index + 1 })),
      ...anniversaries.map(anniversary => ({ date: anniversary.date, type: anniversary.type, loanIndex: index + 1 }))
    ].filter(filing => isValid(filing.date));
  }).flat();
};

const formatDate = (date) => {
  if (isValid(date)) {
    return format(date, 'MMM yyyy');
  }
  return 'Invalid date';
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const { type, record } = payload[0].payload;

    return (
      <div className="custom-tooltip" style={{
        backgroundColor: '#f5f6f8',
        borderRadius: 4,
        border: '1px solid #282828',
        padding: 4,
        fontSize: '12px'
      }}>
        <p className="label">{`Event: ${type === 'Anniversary' ? 'Anniversary' : expandRecordCode(type)}`}</p>
      </div>
    );
  }

  return null;
};

const TimelineChart = ({ records, orgId }) => {
  records = records.filter(record => isFilingActive(record) === 'Active');
  const data = preprocessData(records);

  const minDate = subYears(new Date(), 5).getTime();
  const maxDate = addYears(new Date(), 1).getTime();
  const today = new Date().getTime();

  const getLenderName = (record) => {
    const lender = record.organization_files.find(of => of.record_code === 4);
    return lender ? PrettyOrgName(lender).substring(0, 20) : 'Unknown Lender';
  };

  const renderCustomYAxisTick = (props) => {
    const { x, y, payload } = props;
    const record = records[payload.value - 1];
    const lenderName = getLenderName(record);

    return (
      <Text x={x} y={y} width={100} textAnchor="end" verticalAnchor="middle" fontSize={10}>
        {lenderName}
      </Text>
    );
  };

  const xAxisTickFormatter = (tick) => {
    return format(startOfYear(new Date(tick)), 'MMM yyyy');
  };

  const getTicks = () => {
    const ticks = [];
    for (let year = subYears(new Date(), 5).getFullYear(); year <= addYears(new Date(), 1).getFullYear(); year++) {
      ticks.push(new Date(year, 0, 1).getTime());
    }
    return ticks;
  };

  return (
    <Card variant="outlined" sx={{ boxShadow: 3, borderRadius: 1 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Active Loans Timeline
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <ScatterChart
          width={800}
          height={(100+ (45 * records.length))}
          margin={{ top: 20, right:40, left: 40, bottom: 5 }}
          overflow="hidden"
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="date"
            type="number"
            domain={[minDate, maxDate]}
            tickFormatter={xAxisTickFormatter}
            ticks={getTicks()}
            tick={{ fontSize: 10 }}
          />
          <YAxis
            type="number"
            dataKey="loanIndex"
            domain={[1, records.length]}
            tickCount={records.length}
            tick={renderCustomYAxisTick}
          />
          <Tooltip content={<CustomTooltip />} />
          <ReferenceLine x={today} stroke="red" />
          {records.map((record, index) => (
            <Scatter
              key={index}
              data={data.filter(d => d.loanIndex === index + 1)}
              name={getLenderName(record)}
              fill={index % 2 === 0 ? '#6baed6' : '#bcbddc'}
              line={{ stroke: index % 2 === 0 ? '#6baed6' : '#bcbddc', strokeWidth: 2 }}
              shape="circle"
            />
          ))}
        </ScatterChart>
        <Button component={RouterLink} to={`/profile/${orgId}/loans`} variant="contained" size="small">
          View All Loans
        </Button>
      </CardContent>
    </Card>
  );
};

export default TimelineChart;
