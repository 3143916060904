// src/pages/Home.js

import React, { useState, useEffect } from 'react';
import {
  Box, Card, Container, Typography, List, ListItem, Divider, Avatar, Grid, CircularProgress, Button
} from '@mui/material';
import { useApi } from '../utilities/api';
import FeedItem from '../components/FeedItem';
import { useNavigate } from 'react-router-dom';
import { PrettyOrgName } from '../utilities/Utilities';
import { useAuth0 } from '@auth0/auth0-react';

const Home = () => {
  const api = useApi();
  const navigate = useNavigate();
  const [feed, setFeed] = useState([]);
  const [recentlyViewed, setRecentlyViewed] = useState([]);
  const [following, setFollowing] = useState([]);
  const [userProfile, setUserProfile] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { isAuthenticated } = useAuth0();
  const [hasFetchedProfile, setHasFetchedProfile] = useState(false);

  useEffect(() => {
    if (isAuthenticated && !hasFetchedProfile) {
      fetchUserProfile();
      setHasFetchedProfile(true);
    }
  }, [isAuthenticated, hasFetchedProfile]);

  useEffect(() => {
    if (userProfile.user_preferences && !userProfile.user_preferences.company_name) {
    // if (userProfile.user_preferences) {
      console.log('navigate to preferences page');
      navigate('/preferences');
    }
  }, [userProfile, navigate]);

  const fetchFeed = async () => {
    console.log('fetching feed');
    setIsLoading(true);
    try {
      const response = await api.get('api/users/feed');
      setFeed(response.data);
    } catch (error) {
      console.error('Error fetching feed:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchRecentlyViewed = async () => {
    try {
      const response = await api.get('api/users/recently-viewed');
      setRecentlyViewed(response.data);
    } catch (error) {
      console.error('Error fetching recently viewed companies:', error);
    }
  };

  const fetchFollowedCompanies = async () => {
    console.log('fetching followed companies');
    try {
      const response = await api.get('api/users/following');
      setFollowing(response.data);
    } catch (error) {
      console.error('Error fetching followed companies:', error);
    }
  };

  const fetchUserProfile = async () => {
    console.log('fetch user profile');
    try {
      const response = await api.get('auth/users/me/');
      setUserProfile(response.data);
      console.log('user profile:', response.data);
      window.heap.identify(response.data.email);
      if (!response.data.user_preferences || !response.data.user_preferences.company_name) {
        navigate('/preferences');
      }
      fetchRecentlyViewed();
      fetchFollowedCompanies();
      fetchFeed();
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
  };

  const handleCompanyClick = (companyId) => {
    navigate(`/profile/${companyId}/home`);
  };

  return (
    <Container maxWidth="lg">
      <Grid container spacing={4}>
        {/* User Profile + Recently Viewed Sidebar - 1/4 */}
        <Grid item xs={12} md={3}>
          {/* User Profile Section */}
          <Card
            style={{
              padding: '1rem',
              marginBottom: '1rem',
              background: 'linear-gradient(135deg, #f5f5f5, #ffffff)',
              borderRadius: '4px',
            }}
          >
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <Avatar
                  src={userProfile.avatarUrl}
                  alt={userProfile.first_name}
                  style={{
                    width: 56,
                    height: 56,
                    border: '2px solid #fff',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                  }}
                />
              </Grid>
              <Grid item>
                <Typography variant="h6" style={{ fontWeight: 600 }}>
                  {userProfile.first_name} { userProfile.last_name }
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {userProfile.user_preferences?.company_name}
                </Typography>
              </Grid>
            </Grid>
            <Divider style={{ margin: '1rem 0' }} />
            <Grid container justifyContent="space-between" alignItems="center">
              <Typography variant="body2" style={{ fontWeight: 800, fontSize: '0.75rem' }}>
                Following
              </Typography>
              <Typography variant="body2" style={{ fontWeight: 800, fontSize: '0.75rem', color: '#007bff' }}>
                {userProfile.follow_count}
              </Typography>
            </Grid>
          </Card>

          {/* Recently Viewed Section */}
          <Card
            style={{
              padding: '1rem',
              marginBottom: '1rem',
              background: 'linear-gradient(135deg, #f5f5f5, #ffffff)',
              borderRadius: '4px',
            }}
          >
            <Typography variant="body2" style={{ fontWeight: 600 }} gutterBottom>
              Recently Viewed
            </Typography>
            {recentlyViewed.length === 0 ? (
              <Typography variant="body1" color="textSecondary">
                No recently viewed companies
              </Typography>
            ) : (
              <List>
                {recentlyViewed.map((company) =>
                  company.organization_info !== null && (
                    <React.Fragment key={company.id}>
                      <ListItem
                        button
                        onClick={() => handleCompanyClick(company.organization_info.id)}
                        style={{ display: 'flex', alignItems: 'center', padding: '0.5rem .25rem' }}
                      >
                        <Avatar
                          src={company.organization_info.logo_url ? company.organization_info.logo_url : company.organization_info.name.toUpperCase()}
                          alt={company.organization_info.name.toUpperCase()}
                          style={{
                            width: 36,
                            height: 36,
                            marginRight: 8,
                            border: '2px solid #fff',
                            boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                          }}
                          variant="rounded"
                        />
                        <Typography variant="body2" style={{ fontWeight: 600 }}>
                          {PrettyOrgName(company.organization_info)}
                        </Typography>
                      </ListItem>
                      <Divider />
                    </React.Fragment>
                  )
                )}
              </List>
            )}
          </Card>
        </Grid>

        {/* Feed Items - 1/2 */}
        <Grid item xs={12} md={6}>
          {isLoading ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
              <CircularProgress />
            </Box>
          ) : feed.length === 0 ? (
            <Typography variant="body1" color="textSecondary">
              No feed items found. Follow some companies to see their activity.
            </Typography>
          ) : (
            <List sx={{ pt: 0 }}>
              {feed.map((item, index) => (
                <React.Fragment key={index}>
                  {item.borrowers[0] && (
                    <ListItem sx={{ pt: 0, pb: 2 }}>
                      <FeedItem item={item} />
                    </ListItem>
                  )}
                </React.Fragment>
              ))}
            </List>
          )}
        </Grid>

        {/* Companies I'm Following - 1/4 */}
        <Grid item xs={12} md={3}>
          <Card
            style={{
              padding: '1rem',
              marginBottom: '1rem',
              background: 'linear-gradient(135deg, #f5f5f5, #ffffff)',
              borderRadius: '4px',
            }}
          >
            <Typography variant="body2" style={{ fontWeight: 600 }} gutterBottom>
              Following
            </Typography>
            {following.length === 0 ? (
              <Typography variant="body1" color="textSecondary">
                Not yet following any companies.
              </Typography>
            ) : (
              <List>
                {following.slice(0, 10).map((company) =>
                  company !== null && (
                    <React.Fragment key={company.id}>
                      <ListItem
                        button
                        onClick={() => handleCompanyClick(company.id)}
                        style={{ display: 'flex', alignItems: 'center', padding: '0.5rem .25rem' }}
                      >
                        <Avatar
                          src={company.logo_url ? company.logo_url : company.name.toUpperCase()}
                          alt={company.name.toUpperCase()}
                          style={{
                            width: 36,
                            height: 36,
                            marginRight: 8,
                            border: '2px solid #fff',
                            boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                          }}
                          variant="rounded"
                        />
                        <Typography variant="body2" style={{ fontWeight: 600 }}>
                          {PrettyOrgName(company)}
                        </Typography>
                      </ListItem>
                      <Divider />
                    </React.Fragment>
                  )
                )}
              </List>
            )}
            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 2 }}
              onClick={() => navigate('/following')}
            >
              View All
            </Button>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Home;
