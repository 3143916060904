import React, { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import {
  Card,
  CardContent,
  Typography,
  Divider,
  Box,
  Avatar,
  Grid,
  IconButton,
  ListItem,
  ListItemText,
  ListItemAvatar
} from '@mui/material';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { format, formatDistanceToNow, parse, parseISO, differenceInYears, addYears, isBefore, addDays } from 'date-fns';
import {expandRecordCode, getLenderLogoUrl, isFilingActive} from '../../utilities/Utilities';
import FilingsItem from './FilingsItem';

const FilingsTabScreen = () => {
  const { records } = useOutletContext();
  const [filters, setFilters] = useState({
    activeLoans: true,
    inactiveLoans: true,
    upcomingAnniversaries: true,
  });

  const formatDate = (dateString) => format(parse(dateString, 'yyyyMMdd', new Date()), 'MMM d, yyyy');

  const getBorrowers = (record) => {
    const borrowers = [];
    if (record.organization_files) {
      borrowers.push(...record.organization_files.filter((file) => file.record_code === 2));
    }
    if (record.individual_files) {
      borrowers.push(...record.individual_files.filter((file) => file.record_code === 1));
    }
    return borrowers;
  };

  const getLenders = (record) => {
    const lenders = [];
    if (record.organization_files) {
      lenders.push(...record.organization_files.filter((file) => file.record_code === 4));
    }
    if (record.individual_files) {
      lenders.push(...record.individual_files.filter((file) => file.record_code === 3));
    }
    return lenders;
  };

  const getCurrentLenders = (records) => {
    const activeLoans = records.filter((record) => isFilingActive(record) === 'Active');
    const lenderMap = new Map();

    activeLoans.forEach((record) => {
      record.organization_files
        .filter((file) => file.record_code === 4)
        .forEach((lender) => {
          const normalizedLenderName = lender.name.toLowerCase();
          if (!lenderMap.has(normalizedLenderName)) {
            const formattedLenderName = lender.name
              .split(' ')
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
              .join(' ');
            const logoUrl = getLenderLogoUrl(formattedLenderName);

            lenderMap.set(normalizedLenderName, {
              name: formattedLenderName,
              count: 1,
              logoUrl,
            });
          } else {
            lenderMap.get(normalizedLenderName).count++;
          }
        });
    });

    return Array.from(lenderMap.values());
  };

  const hasUpcomingAnniversary = (files) => {
    const filteredFiles = files.filter(file => file.filing_type === 'C' || file.filing_type === 'MR');
    if (filteredFiles.length === 0) return false;

    const latestFile = filteredFiles.reduce((latest, current) => (
      (latest && parse(latest.filing_date, 'yyyyMMdd', new Date()) > parse(current.filing_date, 'yyyyMMdd', new Date()))
      ? latest
      : current
    ));

    const latestFilingDate = parse(latestFile.filing_date, 'yyyyMMdd', new Date());
    const currentDate = new Date();
    const nextAnniversary = addYears(latestFilingDate, differenceInYears(currentDate, latestFilingDate) + 1);

    return isBefore(nextAnniversary, addDays(currentDate, 90));
  };

  const getAmendments = (record) => (
    record.amendment_files
      .sort((a, b) => b.filing_date.localeCompare(a.filing_date))
      .map((amendment, index) => (
        <ListItem key={index} disablePadding>
          <ListItemAvatar>
            <Avatar>
              <RadioButtonUncheckedIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={`${expandRecordCode(amendment.filing_type)} - ${formatDate(amendment.filing_date)}`}
            secondary={`Filed ${formatDistanceToNow(parseISO(amendment.filing_date), { addSuffix: true })}`}
          />
        </ListItem>
      ))
  );

  const getLoanCard = (record, index) => (
    <FilingsItem
      key={index}
      item={record}
      borrowers={getBorrowers(record)}
      securedParties={getLenders(record)}
      amendments={getAmendments(record)}
      isFilingActive={isFilingActive(record)}
      hasUpcomingAnniversary={hasUpcomingAnniversary(record.amendment_files)}
      filingsCount={23}
    />
  );

  const getLoanStatistics = () => {
    const activeLoans = records.filter((record) => isFilingActive(record) === 'Active').length;
    const inactiveLoans = records.length - activeLoans;
    const upcomingAnniversaries = records.filter((record) => hasUpcomingAnniversary(record.amendment_files)).length;
    const currentLenders = getCurrentLenders(records);

    const renderToggleButton = (filterName) => (
      <IconButton
        onClick={() => handleFilterChange(filterName)}
        size="small"
        sx={{
          marginLeft: 1,
          color: filters[filterName] ? 'primary.main' : 'text.secondary'
        }}
      >
        {filters[filterName] ? <VisibilityIcon /> : <VisibilityOffIcon />}
      </IconButton>
    );

    return (
      <Card variant="outlined" sx={{ boxShadow: 3, borderRadius: 1, padding: 0 }}>
        <CardContent>
          <Typography variant="body2" sx={{ fontWeight: 'bold', mb: 2 }}>
            Borrowing Insights
          </Typography>
          {/*<Box mb={2} display="flex" alignItems="center">*/}
          {/*  <Typography variant="subtitle2" sx={{ flex: 1 }}>Total Loans</Typography>*/}
          {/*  <Typography variant="h6">{records.length}</Typography>*/}
          {/*</Box>*/}
          <Divider sx={{ my: 1 }} />
          <Box my={2} display="flex" alignItems="center">
            <Typography variant="subtitle2" sx={{ flex: 1 }}>Active Loans</Typography>
            <Typography variant="h6" color="green">{activeLoans}</Typography>
            {renderToggleButton('activeLoans')}
          </Box>
          <Divider sx={{ my: 1 }} />
          <Box my={2} display="flex" alignItems="center">
            <Typography variant="subtitle2" sx={{ flex: 1 }}>Inactive Loans</Typography>
            <Typography variant="h6" color="red">{inactiveLoans}</Typography>
            {renderToggleButton('inactiveLoans')}
          </Box>
          <Divider sx={{ my: 1 }} />
          <Box my={2} display="flex" alignItems="center">
            <Typography variant="subtitle2" sx={{ flex: 1 }}>Upcoming Anniversaries</Typography>
            <Typography variant="h6" color="orange">{upcomingAnniversaries}</Typography>
            {renderToggleButton('upcomingAnniversaries')}
          </Box>
          <Divider sx={{ my: 1 }} />
          <Box my={2}>
            <Typography variant="subtitle2" gutterBottom>Current Lenders</Typography>
            {currentLenders.map((lender) => (
              <Box key={lender.id} display="flex" alignItems="center" my={1}>
                <Avatar src={lender.logoUrl} sx={{ width: 24, height: 24, marginRight: 1 }} />
                <Typography variant="body2" sx={{ flex: 1 }}>{lender.name}</Typography>
                <Typography variant="body2">{lender.count}</Typography>
              </Box>
            ))}
          </Box>
        </CardContent>
      </Card>
    );
  };

  const filteredRecords = records.filter((record) => {
    const isActive = isFilingActive(record) === 'Active';
    const hasAnniversary = hasUpcomingAnniversary(record.amendment_files);

    if (filters.activeLoans && isActive) return true;
    if (filters.inactiveLoans && !isActive) return true;
    if (filters.upcomingAnniversaries && hasAnniversary) return true;

    return false;
  });

  const handleFilterChange = (filterName) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: !prevFilters[filterName],
    }));
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={4}>
        {getLoanStatistics()}
      </Grid>
      <Grid item xs={12} md={8}>
        {filteredRecords.map((record, index) => getLoanCard(record, index))}
      </Grid>
    </Grid>
  );
};

export default FilingsTabScreen;
