import { parseISO, formatDistanceToNow } from 'date-fns';
import Fuse from 'fuse.js';
import lenders from './LenderLogos';


const commonWords = new Set([
  "the", "and", "for", "are", "of", "in", "but", "not", "you", "all", "any", "can", "her", "was", "one", "our", "out", "day", "get", "has", "him", "his", "how", "man", "new", "now", "old", "see", "two", "way", "who", "boy", "did", "its", "let", "put", "say", "she", "too", "use" // Add more common words as needed
]);

const isAcronym = (word) => /^[A-Z](?:[.]?[A-Z])+$/.test(word);
const isShortNonWord = (word) => word.length >= 1 && word.length <= 3 && !commonWords.has(word.toLowerCase());

export const PrettyOrgName = (organization) => {
  if (organization.first_name && organization.last_name) {
    return organization.first_name + " " + organization.last_name;
  } else if (organization.g_name) {
    return organization.g_name;
  } else if (organization.pretty_name) {
    return organization.pretty_name;
  } else if (organization.name) {
    // List of known suffixes
    const suffixes = ["LLC", "LLP", "LP", "INC", "CORP", "PLLC", "PC"];
    const words = organization.name.split(" ");
    const formattedWords = words.map((word) => {
      if (suffixes.includes(word.toUpperCase())) {
        return word.toUpperCase();
      }
      else if (commonWords.has(word.toLowerCase())) {
        return word.toLowerCase();
      } else if (isAcronym(word) || isShortNonWord(word)) {
        return word.toUpperCase();
      } else {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }
    });

    return formattedWords.join(" ");
  } else {
    return "Unknown Organization";
  }
};

export const isFilingActive = (record) => {
  const currentDate = new Date();
  const fiveYearsAgo = new Date(
    currentDate.getFullYear() - 5,
    currentDate.getMonth(),
    currentDate.getDate()
  );

  const hasNoTypeT = record.amendment_files.every(
    (file) => file.filing_type !== "T"
  );
  const hasRelevantTypeMRorC = record.amendment_files.some((file) => {
    if (["MR", "C"].includes(file.filing_type)) {
      const fileYear = parseInt(file.filing_date.slice(0, 4));
      const fileMonth = parseInt(file.filing_date.slice(4, 6)) - 1;
      const fileDay = parseInt(file.filing_date.slice(6));
      const fileDate = new Date(fileYear, fileMonth, fileDay);

      return fileDate >= fiveYearsAgo;
    }
    return false;
  });

  return hasNoTypeT && hasRelevantTypeMRorC ? "Active" : "Expired";
}

const recordCodeMap = {
  MR: "Master Record",
  AM: "Amendment, Master Amendment",
  A: "Assignment, Master Assignment",
  CC: "Change Collateral Amendment",
  CP: "Change Party Amendment",
  C: "Continuation",
  O: "Correction",
  IS: "Information Statement",
  J: "Judicial Finding of Fact",
  RV: "Revoked, Revoked edit",
  T: "Termination",
};

export const expandRecordCode = (recordCode) => {
  return recordCodeMap[recordCode] || "Unknown Code";
}

export const getFilingTypeIcon = (recordCode) => {
  switch (recordCode) {
    case 'MR':
      return 'file-plus';
    case 'C':
      return 'file-refresh';
    case 'T':
      return 'file-remove';
    // Add other cases as needed
    default:
      return 'file-document-outline';
  }
};

export const getFilingTypeColors = {
  MR: "#4caf50", // Green for creation
  C: "#ff9800", // Orange for continuation
  T: "#f44336", // Red for termination
  // Add more colors for other types as needed
};

// Utility function to get the icon name based on the filing type
const getIconName = (filingType) => {
  switch(filingType) {
    case "MR":
      return "clock-plus-outline"; // MaterialCommunityIcons name for master record
    case "C":
      return "clock-check-outline"; // Assuming "update" is a fitting icon for continuation
    // Add more cases as needed. Adjust according to your available icons.
    default:
      return "file-outline"; // Default icon
  }
};

// Function to calculate the age and select icon
export const calculateFilingAgeAndIcon = (amendmentFiles) => {
  // Filter for MR and C records and sort them by date descending
  const relevantFiles = amendmentFiles
    // .filter(file => ["MR", "C"].includes(file.filing_type))
    .sort((a, b) => b.filing_date.localeCompare(a.filing_date));

  if (relevantFiles.length === 0) {
    return { age: "No relevant filings", iconName: "folder" };
  }

  const latest = relevantFiles[0];
  const latestDate = parseISO(latest.filing_date);
  const latestType = latest.filing_type;
  const age = formatDistanceToNow(latestDate, { addSuffix: true });
  const ageIconName = getIconName(latest.filing_type);

  return {
    age,
    ageIconName,
    latestType,
    filingType: recordCodeMap[latest.filing_type] || "Unknown Filing Type"
  };
};

export const letterLogo = (organization) => {
  const name = PrettyOrgName(organization);
  return 'alpha-' + name[0].toLowerCase() + '-box';
}

export const getLenderLogoUrl = (lender) => {
  // Configuring Fuse options
  const options = {
    includeScore: true, // This will include the score of each search result
    keys: ['name'],   // This defines which properties of the objects you are searching in
    threshold: .5,
  };

  // Create a new Fuse instance with the lenders array and the options
  const fuse = new Fuse(lenders, options);

  // Searching
  const pattern = lender;
  const result = fuse.search(pattern);

  // Display the best match
  return result.length > 0 ? `https://loanscout-public.s3.us-west-2.amazonaws.com/lender-logos/${result[0].item.fileName}` : null;
}

export const getPrettyFollowType = (followType) => {
  switch (followType) {
    case 'customer':
      return 'Customer';
    case 'prospect':
      return 'Prospect';
    case 'known':
      return 'Known';
    default:
      return 'Following';
  }
}

export const followTypeColorMap = {
  'basic': '#4CAF50',    // Green
  'customer': '#2196F3', // Blue
  'prospect': '#FF9800', // Orange
  'known': '#9C27B0'     // Purple
}

export const ACTION_TYPES = {
  CALL: 'call',
  EMAIL: 'email',
  TEXT: 'text',
  LINKEDIN: 'linkedin',
  OTHER: 'other',
};

// UnitTypes.js
export const UNIT_TYPES = {
  DAYS: 'days',
  WEEKS: 'weeks',
  MONTHS: 'months',
};