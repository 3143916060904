import React, { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Button,
  CircularProgress,
  IconButton,
  Tooltip,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useApi } from "../../utilities/api";

const PlanTab = () => {
  const { profile } = useOutletContext();
  const [steps, setSteps] = useState([]);
  const [loading, setLoading] = useState(false);
  const api = useApi();

  const fetchSteps = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/api/profiles/${profile.id}/generate-plan`);
      setSteps(response.data);
    } catch (error) {
      console.error('Error fetching plan steps:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    alert("Copied to clipboard!");
  };

  return (
    <Grid container spacing={4}>
      {/* Button to create plan */}
      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={fetchSteps} disabled={loading}>
          {loading ? <CircularProgress size={24} /> : 'Create Plan'}
        </Button>
      </Grid>

      {/* Plan Steps Summary */}
      {steps.length > 0 && (
        <Grid item xs={12}>
          <Card variant="outlined" sx={{ boxShadow: 3, borderRadius: 1 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Plan Steps
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <List>
                {steps.map((step, index) => (
                  <ListItem key={index} alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar>
                        <CheckIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={`${step.step_number}. ${step.step_title}`}
                      secondary={
                        <>
                          <Typography variant="body2" color="textPrimary">
                            {step.step_instructions}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            sx={{ whiteSpace: 'pre-wrap', backgroundColor: '#f0f0f0', padding: 1, borderRadius: 1 }}
                          >
                            {step.step_content}
                          </Typography>
                        </>
                      }
                    />
                    <Tooltip title="Copy to clipboard">
                      <IconButton onClick={() => handleCopy(step.step_content)}>
                        <ContentCopyIcon />
                      </IconButton>
                    </Tooltip>
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
        </Grid>
      )}
    </Grid>
  );
};

export default PlanTab;
