const naicsOptions = [
  { code: 111110, title: "Soybean Farming" },
  { code: 111120, title: "Oilseed (except Soybean) Farming " },
  { code: 111130, title: "Dry Pea and Bean Farming " },
  { code: 111140, title: "Wheat Farming" },
  { code: 111150, title: "Corn Farming " },
  { code: 111160, title: "Rice Farming" },
  { code: 111191, title: "Oilseed and Grain Combination Farming " },
  { code: 111199, title: "All Other Grain Farming " },
  { code: 111211, title: "Potato Farming " },
  { code: 111219, title: "Other Vegetable (except Potato) and Melon Farming " },
  { code: 111310, title: "Orange Groves" },
  { code: 111320, title: "Citrus (except Orange) Groves " },
  { code: 111331, title: "Apple Orchards " },
  { code: 111332, title: "Grape Vineyards " },
  { code: 111333, title: "Strawberry Farming " },
  { code: 111334, title: "Berry (except Strawberry) Farming " },
  { code: 111335, title: "Tree Nut Farming " },
  { code: 111336, title: "Fruit and Tree Nut Combination Farming " },
  { code: 111339, title: "Other Noncitrus Fruit Farming " },
  { code: 111411, title: "Mushroom Production " },
  { code: 111419, title: "Other Food Crops Grown Under Cover " },
  { code: 111421, title: "Nursery and Tree Production " },
  { code: 111422, title: "Floriculture Production " },
  { code: 111910, title: "Tobacco Farming" },
  { code: 111920, title: "Cotton Farming" },
  { code: 111930, title: "Sugarcane Farming" },
  { code: 111940, title: "Hay Farming " },
  { code: 111991, title: "Sugar Beet Farming " },
  { code: 111992, title: "Peanut Farming " },
  { code: 111998, title: "All Other Miscellaneous Crop Farming " },
  { code: 112111, title: "Beef Cattle Ranching and Farming " },
  { code: 112112, title: "Cattle Feedlots " },
  { code: 112120, title: "Dairy Cattle and Milk Production" },
  { code: 112130, title: "Dual-Purpose Cattle Ranching and Farming " },
  { code: 112210, title: "Hog and Pig Farming " },
  { code: 112310, title: "Chicken Egg Production " },
  { code: 112320, title: "Broilers and Other Meat Type Chicken Production " },
  { code: 112330, title: "Turkey Production" },
  { code: 112340, title: "Poultry Hatcheries" },
  { code: 112390, title: "Other Poultry Production " },
  { code: 112410, title: "Sheep Farming" },
  { code: 112420, title: "Goat Farming" },
  { code: 112511, title: "Finfish Farming and Fish Hatcheries " },
  { code: 112512, title: "Shellfish Farming " },
  { code: 112519, title: "Other Aquaculture " },
  { code: 112910, title: "Apiculture" },
  { code: 112920, title: "Horses and Other Equine Production" },
  { code: 112930, title: "Fur-Bearing Animal and Rabbit Production" },
  { code: 112990, title: "All Other Animal Production " },
  { code: 113110, title: "Timber Tract Operations" },
  { code: 113210, title: "Forest Nurseries and Gathering of Forest Products " },
  { code: 113310, title: "Logging " },
  { code: 114111, title: "Finfish Fishing " },
  { code: 114112, title: "Shellfish Fishing " },
  { code: 114119, title: "Other Marine Fishing " },
  { code: 114210, title: "Hunting and Trapping" },
  { code: 115111, title: "Cotton Ginning " },
  { code: 115112, title: "Soil Preparation, Planting, and Cultivating " },
  { code: 115113, title: "Crop Harvesting, Primarily by Machine " },
  { code: 115114, title: "Postharvest Crop Activities (except Cotton Ginning) " },
  { code: 115115, title: "Farm Labor Contractors and Crew Leaders " },
  { code: 115116, title: "Farm Management Services " },
  { code: 115210, title: "Support Activities for Animal Production" },
  { code: 115310, title: "Support Activities for Forestry" },
  { code: 211120, title: "Crude Petroleum Extraction " },
  { code: 211130, title: "Natural Gas Extraction " },
  { code: 212114, title: "Surface Coal Mining " },
  { code: 212115, title: "Underground Coal Mining " },
  { code: 212210, title: "Iron Ore Mining" },
  { code: 212220, title: "Gold Ore and Silver Ore Mining " },
  { code: 212230, title: "Copper, Nickel, Lead, and Zinc Mining " },
  { code: 212290, title: "Other Metal Ore Mining " },
  { code: 212311, title: "Dimension Stone Mining and Quarrying " },
  { code: 212312, title: "Crushed and Broken Limestone Mining and Quarrying " },
  { code: 212313, title: "Crushed and Broken Granite Mining and Quarrying " },
  { code: 212319, title: "Other Crushed and Broken Stone Mining and Quarrying " },
  { code: 212321, title: "Construction Sand and Gravel Mining " },
  { code: 212322, title: "Industrial Sand Mining " },
  { code: 212323, title: "Kaolin, Clay, and Ceramic and Refractory Minerals Mining " },
  { code: 212390, title: "Other Nonmetallic Mineral Mining and Quarrying " },
  { code: 213111, title: "Drilling Oil and Gas Wells" },
  { code: 213112, title: "Support Activities for Oil and Gas Operations " },
  { code: 213113, title: "Support Activities for Coal Mining " },
  { code: 213114, title: "Support Activities for Metal Mining " },
  { code: 213115, title: "Support Activities for Nonmetallic Minerals (except Fuels) Mining " },
  { code: 221111, title: "Hydroelectric Power Generation " },
  { code: 221112, title: "Fossil Fuel Electric Power Generation " },
  { code: 221113, title: "Nuclear Electric Power Generation " },
  { code: 221114, title: "Solar Electric Power Generation " },
  { code: 221115, title: "Wind Electric Power Generation " },
  { code: 221116, title: "Geothermal Electric Power Generation " },
  { code: 221117, title: "Biomass Electric Power Generation " },
  { code: 221118, title: "Other Electric Power Generation " },
  { code: 221121, title: "Electric Bulk Power Transmission and Control " },
  { code: 221122, title: "Electric Power Distribution " },
  { code: 221210, title: "Natural Gas Distribution " },
  { code: 221310, title: "Water Supply and Irrigation Systems " },
  { code: 221320, title: "Sewage Treatment Facilities " },
  { code: 221330, title: "Steam and Air-Conditioning Supply " },
  { code: 236115, title: "New Single-Family Housing Construction (except For-Sale Builders) " },
  { code: 236116, title: "New Multifamily Housing Construction (except For-Sale Builders) " },
  { code: 236117, title: "New Housing For-Sale Builders " },
  { code: 236118, title: "Residential Remodelers " },
  { code: 236210, title: "Industrial Building Construction " },
  { code: 236220, title: "Commercial and Institutional Building Construction " },
  { code: 237110, title: "Water and Sewer Line and Related Structures Construction " },
  { code: 237120, title: "Oil and Gas Pipeline and Related Structures Construction " },
  { code: 237130, title: "Power and Communication Line and Related Structures Construction " },
  { code: 237210, title: "Land Subdivision " },
  { code: 237310, title: "Highway, Street, and Bridge Construction " },
  { code: 237990, title: "Other Heavy and Civil Engineering Construction " },
  { code: 238110, title: "Poured Concrete Foundation and Structure Contractors " },
  { code: 238120, title: "Structural Steel and Precast Concrete Contractors " },
  { code: 238130, title: "Framing Contractors " },
  { code: 238140, title: "Masonry Contractors " },
  { code: 238150, title: "Glass and Glazing Contractors " },
  { code: 238160, title: "Roofing Contractors " },
  { code: 238170, title: "Siding Contractors " },
  { code: 238190, title: "Other Foundation, Structure, and Building Exterior Contractors " },
  { code: 238210, title: "Electrical Contractors and Other Wiring Installation Contractors" },
  { code: 238220, title: "Plumbing, Heating, and Air-Conditioning Contractors " },
  { code: 238290, title: "Other Building Equipment Contractors " },
  { code: 238310, title: "Drywall and Insulation Contractors " },
  { code: 238320, title: "Painting and Wall Covering Contractors" },
  { code: 238330, title: "Flooring Contractors" },
  { code: 238340, title: "Tile and Terrazzo Contractors" },
  { code: 238350, title: "Finish Carpentry Contractors" },
  { code: 238390, title: "Other Building Finishing Contractors" },
  { code: 238910, title: "Site Preparation Contractors" },
  { code: 238990, title: "All Other Specialty Trade Contractors" },
  { code: 311111, title: "Dog and Cat Food Manufacturing " },
  { code: 311119, title: "Other Animal Food Manufacturing " },
  { code: 311211, title: "Flour Milling " },
  { code: 311212, title: "Rice Milling " },
  { code: 311213, title: "Malt Manufacturing " },
  { code: 311221, title: "Wet Corn Milling and Starch Manufacturing " },
  { code: 311224, title: "Soybean and Other Oilseed Processing " },
  { code: 311225, title: "Fats and Oils Refining and Blending " },
  { code: 311230, title: "Breakfast Cereal Manufacturing" },
  { code: 311313, title: "Beet Sugar Manufacturing " },
  { code: 311314, title: "Cane Sugar Manufacturing " },
  { code: 311340, title: "Nonchocolate Confectionery Manufacturing" },
  { code: 311351, title: "Chocolate and Confectionery Manufacturing from Cacao Beans " },
  { code: 311352, title: "Confectionery Manufacturing from Purchased Chocolate " },
  { code: 311411, title: "Frozen Fruit, Juice, and Vegetable Manufacturing " },
  { code: 311412, title: "Frozen Specialty Food Manufacturing " },
  { code: 311421, title: "Fruit and Vegetable Canning " },
  { code: 311422, title: "Specialty Canning " },
  { code: 311423, title: "Dried and Dehydrated Food Manufacturing " },
  { code: 311511, title: "Fluid Milk Manufacturing " },
  { code: 311512, title: "Creamery Butter Manufacturing " },
  { code: 311513, title: "Cheese Manufacturing " },
  { code: 311514, title: "Dry, Condensed, and Evaporated Dairy Product Manufacturing " },
  { code: 311520, title: "Ice Cream and Frozen Dessert Manufacturing" },
  { code: 311611, title: "Animal (except Poultry) Slaughtering " },
  { code: 311612, title: "Meat Processed from Carcasses " },
  { code: 311613, title: "Rendering and Meat Byproduct Processing " },
  { code: 311615, title: "Poultry Processing " },
  { code: 311710, title: "Seafood Product Preparation and Packaging" },
  { code: 311811, title: "Retail Bakeries " },
  { code: 311812, title: "Commercial Bakeries " },
  { code: 311813, title: "Frozen Cakes, Pies, and Other Pastries Manufacturing " },
  { code: 311821, title: "Cookie and Cracker Manufacturing " },
  { code: 311824, title: "Dry Pasta, Dough, and Flour Mixes Manufacturing from Purchased Flour " },
  { code: 311830, title: "Tortilla Manufacturing" },
  { code: 311911, title: "Roasted Nuts and Peanut Butter Manufacturing " },
  { code: 311919, title: "Other Snack Food Manufacturing " },
  { code: 311920, title: "Coffee and Tea Manufacturing " },
  { code: 311930, title: "Flavoring Syrup and Concentrate Manufacturing" },
  { code: 311941, title: "Mayonnaise, Dressing, and Other Prepared Sauce Manufacturing " },
  { code: 311942, title: "Spice and Extract Manufacturing " },
  { code: 311991, title: "Perishable Prepared Food Manufacturing " },
  { code: 311999, title: "All Other Miscellaneous Food Manufacturing " },
  { code: 312111, title: "Soft Drink Manufacturing " },
  { code: 312112, title: "Bottled Water Manufacturing " },
  { code: 312113, title: "Ice Manufacturing " },
  { code: 312120, title: "Breweries" },
  { code: 312130, title: "Wineries " },
  { code: 312140, title: "Distilleries " },
  { code: 312230, title: "Tobacco Manufacturing " },
  { code: 313110, title: "Fiber, Yarn, and Thread Mills " },
  { code: 313210, title: "Broadwoven Fabric Mills" },
  { code: 313220, title: "Narrow Fabric Mills and Schiffli Machine Embroidery" },
  { code: 313230, title: "Nonwoven Fabric Mills" },
  { code: 313240, title: "Knit Fabric Mills" },
  { code: 313310, title: "Textile and Fabric Finishing Mills " },
  { code: 313320, title: "Fabric Coating Mills" },
  { code: 314110, title: "Carpet and Rug Mills" },
  { code: 314120, title: "Curtain and Linen Mills" },
  { code: 314910, title: "Textile Bag and Canvas Mills " },
  { code: 314994, title: "Rope, Cordage, Twine, Tire Cord, and Tire Fabric Mills " },
  { code: 314999, title: "All Other Miscellaneous Textile Product Mills " },
  { code: 315120, title: "Apparel Knitting Mills" },
  { code: 315210, title: "Cut and Sew Apparel Contractors " },
  { code: 315250, title: "Cut and Sew Apparel Manufacturing (except Contractors) " },
  { code: 315990, title: "Apparel Accessories and Other Apparel Manufacturing " },
  { code: 316110, title: "Leather and Hide Tanning and Finishing" },
  { code: 316210, title: "Footwear Manufacturing " },
  { code: 316990, title: "Other Leather and Allied Product Manufacturing " },
  { code: 321113, title: "Sawmills " },
  { code: 321114, title: "Wood Preservation " },
  { code: 321211, title: "Hardwood Veneer and Plywood Manufacturing " },
  { code: 321212, title: "Softwood Veneer and Plywood Manufacturing " },
  { code: 321215, title: "Engineered Wood Member Manufacturing " },
  { code: 321219, title: "Reconstituted Wood Product Manufacturing " },
  { code: 321911, title: "Wood Window and Door Manufacturing " },
  { code: 321912, title: "Cut Stock, Resawing Lumber, and Planing " },
  { code: 321918, title: "Other Millwork (including Flooring) " },
  { code: 321920, title: "Wood Container and Pallet Manufacturing" },
  { code: 321991, title: "Manufactured Home (Mobile Home) Manufacturing " },
  { code: 321992, title: "Prefabricated Wood Building Manufacturing " },
  { code: 321999, title: "All Other Miscellaneous Wood Product Manufacturing " },
  { code: 322110, title: "Pulp Mills " },
  { code: 322120, title: "Paper Mills " },
  { code: 322130, title: "Paperboard Mills " },
  { code: 322211, title: "Corrugated and Solid Fiber Box Manufacturing " },
  { code: 322212, title: "Folding Paperboard Box Manufacturing " },
  { code: 322219, title: "Other Paperboard Container Manufacturing " },
  { code: 322220, title: "Paper Bag and Coated and Treated Paper Manufacturing" },
  { code: 322230, title: "Stationery Product Manufacturing" },
  { code: 322291, title: "Sanitary Paper Product Manufacturing " },
  { code: 322299, title: "All Other Converted Paper Product Manufacturing " },
  { code: 323111, title: "Commercial Printing (except Screen and Books) " },
  { code: 323113, title: "Commercial Screen Printing " },
  { code: 323117, title: "Books Printing " },
  { code: 323120, title: "Support Activities for Printing" },
  { code: 324110, title: "Petroleum Refineries" },
  { code: 324121, title: "Asphalt Paving Mixture and Block Manufacturing " },
  { code: 324122, title: "Asphalt Shingle and Coating Materials Manufacturing " },
  { code: 324191, title: "Petroleum Lubricating Oil and Grease Manufacturing " },
  { code: 324199, title: "All Other Petroleum and Coal Products Manufacturing " },
  { code: 325110, title: "Petrochemical Manufacturing" },
  { code: 325120, title: "Industrial Gas Manufacturing" },
  { code: 325130, title: "Synthetic Dye and Pigment Manufacturing" },
  { code: 325180, title: "Other Basic Inorganic Chemical Manufacturing " },
  { code: 325193, title: "Ethyl Alcohol Manufacturing " },
  { code: 325194, title: "Cyclic Crude, Intermediate, and Gum and Wood Chemical Manufacturing " },
  { code: 325199, title: "All Other Basic Organic Chemical Manufacturing " },
  { code: 325211, title: "Plastics Material and Resin Manufacturing " },
  { code: 325212, title: "Synthetic Rubber Manufacturing " },
  { code: 325220, title: "Artificial and Synthetic Fibers and Filaments Manufacturing" },
  { code: 325311, title: "Nitrogenous Fertilizer Manufacturing " },
  { code: 325312, title: "Phosphatic Fertilizer Manufacturing " },
  { code: 325314, title: "Fertilizer (Mixing Only) Manufacturing " },
  { code: 325315, title: "Compost Manufacturing" },
  { code: 325320, title: "Pesticide and Other Agricultural Chemical Manufacturing" },
  { code: 325411, title: "Medicinal and Botanical Manufacturing " },
  { code: 325412, title: "Pharmaceutical Preparation Manufacturing " },
  { code: 325413, title: "In-Vitro Diagnostic Substance Manufacturing " },
  { code: 325414, title: "Biological Product (except Diagnostic) Manufacturing " },
  { code: 325510, title: "Paint and Coating Manufacturing" },
  { code: 325520, title: "Adhesive Manufacturing" },
  { code: 325611, title: "Soap and Other Detergent Manufacturing " },
  { code: 325612, title: "Polish and Other Sanitation Good Manufacturing " },
  { code: 325613, title: "Surface Active Agent Manufacturing " },
  { code: 325620, title: "Toilet Preparation Manufacturing" },
  { code: 325910, title: "Printing Ink Manufacturing" },
  { code: 325920, title: "Explosives Manufacturing" },
  { code: 325991, title: "Custom Compounding of Purchased Resins " },
  { code: 325992, title: "Photographic Film, Paper, Plate, Chemical, and Copy Toner Manufacturing " },
  { code: 325998, title: "All Other Miscellaneous Chemical Product and Preparation Manufacturing " },
  { code: 326111, title: "Plastics Bag and Pouch Manufacturing " },
  { code: 326112, title: "Plastics Packaging Film and Sheet (including Laminated) Manufacturing " },
  { code: 326113, title: "Unlaminated Plastics Film and Sheet (except Packaging) Manufacturing " },
  { code: 326121, title: "Unlaminated Plastics Profile Shape Manufacturing " },
  { code: 326122, title: "Plastics Pipe and Pipe Fitting Manufacturing " },
  { code: 326130, title: "Laminated Plastics Plate, Sheet (except Packaging), and Shape Manufacturing" },
  { code: 326140, title: "Polystyrene Foam Product Manufacturing" },
  { code: 326150, title: "Urethane and Other Foam Product (except Polystyrene) Manufacturing" },
  { code: 326160, title: "Plastics Bottle Manufacturing" },
  { code: 326191, title: "Plastics Plumbing Fixture Manufacturing " },
  { code: 326199, title: "All Other Plastics Product Manufacturing " },
  { code: 326211, title: "Tire Manufacturing (except Retreading) " },
  { code: 326212, title: "Tire Retreading " },
  { code: 326220, title: "Rubber and Plastics Hoses and Belting Manufacturing" },
  { code: 326291, title: "Rubber Product Manufacturing for Mechanical Use " },
  { code: 326299, title: "All Other Rubber Product Manufacturing " },
  { code: 327110, title: "Pottery, Ceramics, and Plumbing Fixture Manufacturing " },
  { code: 327120, title: "Clay Building Material and Refractories Manufacturing " },
  { code: 327211, title: "Flat Glass Manufacturing " },
  { code: 327212, title: "Other Pressed and Blown Glass and Glassware Manufacturing " },
  { code: 327213, title: "Glass Container Manufacturing " },
  { code: 327215, title: "Glass Product Manufacturing Made of Purchased Glass " },
  { code: 327310, title: "Cement Manufacturing" },
  { code: 327320, title: "Ready-Mix Concrete Manufacturing" },
  { code: 327331, title: "Concrete Block and Brick Manufacturing " },
  { code: 327332, title: "Concrete Pipe Manufacturing " },
  { code: 327390, title: "Other Concrete Product Manufacturing " },
  { code: 327410, title: "Lime Manufacturing" },
  { code: 327420, title: "Gypsum Product Manufacturing" },
  { code: 327910, title: "Abrasive Product Manufacturing" },
  { code: 327991, title: "Cut Stone and Stone Product Manufacturing " },
  { code: 327992, title: "Ground or Treated Mineral and Earth Manufacturing " },
  { code: 327993, title: "Mineral Wool Manufacturing " },
  { code: 327999, title: "All Other Miscellaneous Nonmetallic Mineral Product Manufacturing " },
  { code: 331110, title: "Iron and Steel Mills and Ferroalloy Manufacturing " },
  { code: 331210, title: "Iron and Steel Pipe and Tube Manufacturing from Purchased Steel" },
  { code: 331221, title: "Rolled Steel Shape Manufacturing " },
  { code: 331222, title: "Steel Wire Drawing " },
  { code: 331313, title: "Alumina Refining and Primary Aluminum Production " },
  { code: 331314, title: "Secondary Smelting and Alloying of Aluminum " },
  { code: 331315, title: "Aluminum Sheet, Plate, and Foil Manufacturing " },
  { code: 331318, title: "Other Aluminum Rolling, Drawing, and Extruding " },
  { code: 331410, title: "Nonferrous Metal (except Aluminum) Smelting and Refining " },
  { code: 331420, title: "Copper Rolling, Drawing, Extruding, and Alloying" },
  { code: 331491, title: "Nonferrous Metal (except Copper and Aluminum) Rolling, Drawing, and Extruding " },
  { code: 331492, title: "Secondary Smelting, Refining, and Alloying of Nonferrous Metal (except Copper and Aluminum) " },
  { code: 331511, title: "Iron Foundries " },
  { code: 331512, title: "Steel Investment Foundries " },
  { code: 331513, title: "Steel Foundries (except Investment) " },
  { code: 331523, title: "Nonferrous Metal Die-Casting Foundries " },
  { code: 331524, title: "Aluminum Foundries (except Die-Casting) " },
  { code: 331529, title: "Other Nonferrous Metal Foundries (except Die-Casting) " },
  { code: 332111, title: "Iron and Steel Forging " },
  { code: 332112, title: "Nonferrous Forging " },
  { code: 332114, title: "Custom Roll Forming " },
  { code: 332117, title: "Powder Metallurgy Part Manufacturing " },
  { code: 332119, title: "Metal Crown, Closure, and Other Metal Stamping (except Automotive) " },
  { code: 332215, title: "Metal Kitchen Cookware, Utensil, Cutlery, and Flatware (except Precious) Manufacturing " },
  { code: 332216, title: "Saw Blade and Handtool Manufacturing " },
  { code: 332311, title: "Prefabricated Metal Building and Component Manufacturing " },
  { code: 332312, title: "Fabricated Structural Metal Manufacturing " },
  { code: 332313, title: "Plate Work Manufacturing " },
  { code: 332321, title: "Metal Window and Door Manufacturing " },
  { code: 332322, title: "Sheet Metal Work Manufacturing " },
  { code: 332323, title: "Ornamental and Architectural Metal Work Manufacturing " },
  { code: 332410, title: "Power Boiler and Heat Exchanger Manufacturing" },
  { code: 332420, title: "Metal Tank (Heavy Gauge) Manufacturing" },
  { code: 332431, title: "Metal Can Manufacturing " },
  { code: 332439, title: "Other Metal Container Manufacturing " },
  { code: 332510, title: "Hardware Manufacturing" },
  { code: 332613, title: "Spring Manufacturing " },
  { code: 332618, title: "Other Fabricated Wire Product Manufacturing " },
  { code: 332710, title: "Machine Shops" },
  { code: 332721, title: "Precision Turned Product Manufacturing " },
  { code: 332722, title: "Bolt, Nut, Screw, Rivet, and Washer Manufacturing " },
  { code: 332811, title: "Metal Heat Treating " },
  { code: 332812, title: "Metal Coating, Engraving (except Jewelry and Silverware), and Allied Services to Manufacturers " },
  { code: 332813, title: "Electroplating, Plating, Polishing, Anodizing, and Coloring " },
  { code: 332911, title: "Industrial Valve Manufacturing " },
  { code: 332912, title: "Fluid Power Valve and Hose Fitting Manufacturing " },
  { code: 332913, title: "Plumbing Fixture Fitting and Trim Manufacturing " },
  { code: 332919, title: "Other Metal Valve and Pipe Fitting Manufacturing " },
  { code: 332991, title: "Ball and Roller Bearing Manufacturing" },
  { code: 332992, title: "Small Arms Ammunition Manufacturing " },
  { code: 332993, title: "Ammunition (except Small Arms) Manufacturing " },
  { code: 332994, title: "Small Arms, Ordnance, and Ordnance Accessories Manufacturing " },
  { code: 332996, title: "Fabricated Pipe and Pipe Fitting Manufacturing " },
  { code: 332999, title: "All Other Miscellaneous Fabricated Metal Product Manufacturing " },
  { code: 333111, title: "Farm Machinery and Equipment Manufacturing " },
  { code: 333112, title: "Lawn and Garden Tractor and Home Lawn and Garden Equipment Manufacturing " },
  { code: 333120, title: "Construction Machinery Manufacturing" },
  { code: 333131, title: "Mining Machinery and Equipment Manufacturing " },
  { code: 333132, title: "Oil and Gas Field Machinery and Equipment Manufacturing " },
  { code: 333241, title: "Food Product Machinery Manufacturing " },
  { code: 333242, title: "Semiconductor Machinery Manufacturing " },
  { code: 333243, title: "Sawmill, Woodworking, and Paper Machinery Manufacturing " },
  { code: 333248, title: "All Other Industrial Machinery Manufacturing " },
  { code: 333310, title: "Commercial and Service Industry Machinery Manufacturing " },
  { code: 333413, title: "Industrial and Commercial Fan and Blower and Air Purification Equipment Manufacturing " },
  { code: 333414, title: "Heating Equipment (except Warm Air Furnaces) Manufacturing " },
  { code: 333415, title: "Air-Conditioning and Warm Air Heating Equipment and Commercial and Industrial Refrigeration Equipment Manufacturing " },
  { code: 333511, title: "Industrial Mold Manufacturing " },
  { code: 333514, title: "Special Die and Tool, Die Set, Jig, and Fixture Manufacturing " },
  { code: 333515, title: "Cutting Tool and Machine Tool Accessory Manufacturing " },
  { code: 333517, title: "Machine Tool Manufacturing " },
  { code: 333519, title: "Rolling Mill and Other Metalworking Machinery Manufacturing " },
  { code: 333611, title: "Turbine and Turbine Generator Set Units Manufacturing " },
  { code: 333612, title: "Speed Changer, Industrial High-Speed Drive, and Gear Manufacturing " },
  { code: 333613, title: "Mechanical Power Transmission Equipment Manufacturing " },
  { code: 333618, title: "Other Engine Equipment Manufacturing " },
  { code: 333912, title: "Air and Gas Compressor Manufacturing " },
  { code: 333914, title: "Measuring, Dispensing, and Other Pumping Equipment Manufacturing " },
  { code: 333921, title: "Elevator and Moving Stairway Manufacturing " },
  { code: 333922, title: "Conveyor and Conveying Equipment Manufacturing " },
  { code: 333923, title: "Overhead Traveling Crane, Hoist, and Monorail System Manufacturing " },
  { code: 333924, title: "Industrial Truck, Tractor, Trailer, and Stacker Machinery Manufacturing " },
  { code: 333991, title: "Power-Driven Handtool Manufacturing " },
  { code: 333992, title: "Welding and Soldering Equipment Manufacturing " },
  { code: 333993, title: "Packaging Machinery Manufacturing " },
  { code: 333994, title: "Industrial Process Furnace and Oven Manufacturing " },
  { code: 333995, title: "Fluid Power Cylinder and Actuator Manufacturing " },
  { code: 333996, title: "Fluid Power Pump and Motor Manufacturing " },
  { code: 333998, title: "All Other Miscellaneous General Purpose Machinery Manufacturing " },
  { code: 334111, title: "Electronic Computer Manufacturing " },
  { code: 334112, title: "Computer Storage Device Manufacturing " },
  { code: 334118, title: "Computer Terminal and Other Computer Peripheral Equipment Manufacturing " },
  { code: 334210, title: "Telephone Apparatus Manufacturing" },
  { code: 334220, title: "Radio and Television Broadcasting and Wireless Communications Equipment Manufacturing" },
  { code: 334290, title: "Other Communications Equipment Manufacturing" },
  { code: 334310, title: "Audio and Video Equipment Manufacturing" },
  { code: 334412, title: "Bare Printed Circuit Board Manufacturing  " },
  { code: 334413, title: "Semiconductor and Related Device Manufacturing " },
  { code: 334416, title: "Capacitor, Resistor, Coil, Transformer, and Other Inductor Manufacturing " },
  { code: 334417, title: "Electronic Connector Manufacturing " },
  { code: 334418, title: "Printed Circuit Assembly (Electronic Assembly) Manufacturing " },
  { code: 334419, title: "Other Electronic Component Manufacturing " },
  { code: 334510, title: "Electromedical and Electrotherapeutic Apparatus Manufacturing " },
  { code: 334511, title: "Search, Detection, Navigation, Guidance, Aeronautical, and Nautical System and Instrument Manufacturing " },
  { code: 334512, title: "Automatic Environmental Control Manufacturing for Residential, Commercial, and Appliance Use " },
  { code: 334513, title: "Instruments and Related Products Manufacturing for Measuring, Displaying, and Controlling Industrial Process Variables " },
  { code: 334514, title: "Totalizing Fluid Meter and Counting Device Manufacturing " },
  { code: 334515, title: "Instrument Manufacturing for Measuring and Testing Electricity and Electrical Signals " },
  { code: 334516, title: "Analytical Laboratory Instrument Manufacturing " },
  { code: 334517, title: "Irradiation Apparatus Manufacturing " },
  { code: 334519, title: "Other Measuring and Controlling Device Manufacturing " },
  { code: 334610, title: "Manufacturing and Reproducing Magnetic and Optical Media " },
  { code: 335131, title: "Residential Electric Lighting Fixture Manufacturing " },
  { code: 335132, title: "Commercial, Industrial, and Institutional Electric Lighting Fixture Manufacturing " },
  { code: 335139, title: "Electric Lamp Bulb and Other Lighting Equipment Manufacturing " },
  { code: 335210, title: "Small Electrical Appliance Manufacturing" },
  { code: 335220, title: "Major Household Appliance Manufacturing " },
  { code: 335311, title: "Power, Distribution, and Specialty Transformer Manufacturing " },
  { code: 335312, title: "Motor and Generator Manufacturing " },
  { code: 335313, title: "Switchgear and Switchboard Apparatus Manufacturing " },
  { code: 335314, title: "Relay and Industrial Control Manufacturing " },
  { code: 335910, title: "Battery Manufacturing " },
  { code: 335921, title: "Fiber Optic Cable Manufacturing " },
  { code: 335929, title: "Other Communication and Energy Wire Manufacturing " },
  { code: 335931, title: "Current-Carrying Wiring Device Manufacturing " },
  { code: 335932, title: "Noncurrent-Carrying Wiring Device Manufacturing " },
  { code: 335991, title: "Carbon and Graphite Product Manufacturing " },
  { code: 335999, title: "All Other Miscellaneous Electrical Equipment and Component Manufacturing " },
  { code: 336110, title: "Automobile and Light Duty Motor Vehicle Manufacturing " },
  { code: 336120, title: "Heavy Duty Truck Manufacturing" },
  { code: 336211, title: "Motor Vehicle Body Manufacturing " },
  { code: 336212, title: "Truck Trailer Manufacturing " },
  { code: 336213, title: "Motor Home Manufacturing " },
  { code: 336214, title: "Travel Trailer and Camper Manufacturing " },
  { code: 336310, title: "Motor Vehicle Gasoline Engine and Engine Parts Manufacturing" },
  { code: 336320, title: "Motor Vehicle Electrical and Electronic Equipment Manufacturing" },
  { code: 336330, title: "Motor Vehicle Steering and Suspension Components (except Spring) Manufacturing" },
  { code: 336340, title: "Motor Vehicle Brake System Manufacturing" },
  { code: 336350, title: "Motor Vehicle Transmission and Power Train Parts Manufacturing" },
  { code: 336360, title: "Motor Vehicle Seating and Interior Trim Manufacturing" },
  { code: 336370, title: "Motor Vehicle Metal Stamping" },
  { code: 336390, title: "Other Motor Vehicle Parts Manufacturing" },
  { code: 336411, title: "Aircraft Manufacturing " },
  { code: 336412, title: "Aircraft Engine and Engine Parts Manufacturing " },
  { code: 336413, title: "Other Aircraft Parts and Auxiliary Equipment Manufacturing " },
  { code: 336414, title: "Guided Missile and Space Vehicle Manufacturing " },
  { code: 336415, title: "Guided Missile and Space Vehicle Propulsion Unit and Propulsion Unit Parts Manufacturing " },
  { code: 336419, title: "Other Guided Missile and Space Vehicle Parts and Auxiliary Equipment Manufacturing " },
  { code: 336510, title: "Railroad Rolling Stock Manufacturing" },
  { code: 336611, title: "Ship Building and Repairing " },
  { code: 336612, title: "Boat Building " },
  { code: 336991, title: "Motorcycle, Bicycle, and Parts Manufacturing " },
  { code: 336992, title: "Military Armored Vehicle, Tank, and Tank Component Manufacturing " },
  { code: 336999, title: "All Other Transportation Equipment Manufacturing " },
  { code: 337110, title: "Wood Kitchen Cabinet and Countertop Manufacturing" },
  { code: 337121, title: "Upholstered Household Furniture Manufacturing " },
  { code: 337122, title: "Nonupholstered Wood Household Furniture Manufacturing " },
  { code: 337126, title: "Household Furniture (except Wood and Upholstered) Manufacturing " },
  { code: 337127, title: "Institutional Furniture Manufacturing " },
  { code: 337211, title: "Wood Office Furniture Manufacturing " },
  { code: 337212, title: "Custom Architectural Woodwork and Millwork Manufacturing " },
  { code: 337214, title: "Office Furniture (except Wood) Manufacturing " },
  { code: 337215, title: "Showcase, Partition, Shelving, and Locker Manufacturing " },
  { code: 337910, title: "Mattress Manufacturing" },
  { code: 337920, title: "Blind and Shade Manufacturing" },
  { code: 339112, title: "Surgical and Medical Instrument Manufacturing " },
  { code: 339113, title: "Surgical Appliance and Supplies Manufacturing " },
  { code: 339114, title: "Dental Equipment and Supplies Manufacturing " },
  { code: 339115, title: "Ophthalmic Goods Manufacturing " },
  { code: 339116, title: "Dental Laboratories " },
  { code: 339910, title: "Jewelry and Silverware Manufacturing " },
  { code: 339920, title: "Sporting and Athletic Goods Manufacturing" },
  { code: 339930, title: "Doll, Toy, and Game Manufacturing" },
  { code: 339940, title: "Office Supplies (except Paper) Manufacturing" },
  { code: 339950, title: "Sign Manufacturing" },
  { code: 339991, title: "Gasket, Packing, and Sealing Device Manufacturing " },
  { code: 339992, title: "Musical Instrument Manufacturing " },
  { code: 339993, title: "Fastener, Button, Needle, and Pin Manufacturing " },
  { code: 339994, title: "Broom, Brush, and Mop Manufacturing " },
  { code: 339995, title: "Burial Casket Manufacturing " },
  { code: 339999, title: "All Other Miscellaneous Manufacturing " },
  { code: 423110, title: "Automobile and Other Motor Vehicle Merchant Wholesalers " },
  { code: 423120, title: "Motor Vehicle Supplies and New Parts Merchant Wholesalers " },
  { code: 423130, title: "Tire and Tube Merchant Wholesalers " },
  { code: 423140, title: "Motor Vehicle Parts (Used) Merchant Wholesalers " },
  { code: 423210, title: "Furniture Merchant Wholesalers " },
  { code: 423220, title: "Home Furnishing Merchant Wholesalers " },
  { code: 423310, title: "Lumber, Plywood, Millwork, and Wood Panel Merchant Wholesalers " },
  { code: 423320, title: "Brick, Stone, and Related Construction Material Merchant Wholesalers " },
  { code: 423330, title: "Roofing, Siding, and Insulation Material Merchant Wholesalers " },
  { code: 423390, title: "Other Construction Material Merchant Wholesalers " },
  { code: 423410, title: "Photographic Equipment and Supplies Merchant Wholesalers " },
  { code: 423420, title: "Office Equipment Merchant Wholesalers " },
  { code: 423430, title: "Computer and Computer Peripheral Equipment and Software Merchant Wholesalers " },
  { code: 423440, title: "Other Commercial Equipment Merchant Wholesalers " },
  { code: 423450, title: "Medical, Dental, and Hospital Equipment and Supplies Merchant Wholesalers " },
  { code: 423460, title: "Ophthalmic Goods Merchant Wholesalers " },
  { code: 423490, title: "Other Professional Equipment and Supplies Merchant Wholesalers " },
  { code: 423510, title: "Metal Service Centers and Other Metal Merchant Wholesalers " },
  { code: 423520, title: "Coal and Other Mineral and Ore Merchant Wholesalers " },
  { code: 423610, title: "Electrical Apparatus and Equipment, Wiring Supplies, and Related Equipment Merchant Wholesalers " },
  { code: 423620, title: "Household Appliances, Electric Housewares, and Consumer Electronics Merchant Wholesalers " },
  { code: 423690, title: "Other Electronic Parts and Equipment Merchant Wholesalers " },
  { code: 423710, title: "Hardware Merchant Wholesalers " },
  { code: 423720, title: "Plumbing and Heating Equipment and Supplies (Hydronics) Merchant Wholesalers " },
  { code: 423730, title: "Warm Air Heating and Air-Conditioning Equipment and Supplies Merchant Wholesalers " },
  { code: 423740, title: "Refrigeration Equipment and Supplies Merchant Wholesalers " },
  { code: 423810, title: "Construction and Mining (except Oil Well) Machinery and Equipment Merchant Wholesalers " },
  { code: 423820, title: "Farm and Garden Machinery and Equipment Merchant Wholesalers " },
  { code: 423830, title: "Industrial Machinery and Equipment Merchant Wholesalers " },
  { code: 423840, title: "Industrial Supplies Merchant Wholesalers" },
  { code: 423850, title: "Service Establishment Equipment and Supplies Merchant Wholesalers " },
  { code: 423860, title: "Transportation Equipment and Supplies (except Motor Vehicle) Merchant Wholesalers " },
  { code: 423910, title: "Sporting and Recreational Goods and Supplies Merchant Wholesalers " },
  { code: 423920, title: "Toy and Hobby Goods and Supplies Merchant Wholesalers " },
  { code: 423930, title: "Recyclable Material Merchant Wholesalers " },
  { code: 423940, title: "Jewelry, Watch, Precious Stone, and Precious Metal Merchant Wholesalers " },
  { code: 423990, title: "Other Miscellaneous Durable Goods Merchant Wholesalers " },
  { code: 424110, title: "Printing and Writing Paper Merchant Wholesalers " },
  { code: 424120, title: "Stationery and Office Supplies Merchant Wholesalers " },
  { code: 424130, title: "Industrial and Personal Service Paper Merchant Wholesalers " },
  { code: 424210, title: "Drugs and Druggists' Sundries Merchant Wholesalers" },
  { code: 424310, title: "Piece Goods, Notions, and Other Dry Goods Merchant Wholesalers " },
  { code: 424340, title: "Footwear Merchant Wholesalers " },
  { code: 424350, title: "Clothing and Clothing Accessories Merchant Wholesalers" },
  { code: 424410, title: "General Line Grocery Merchant Wholesalers " },
  { code: 424420, title: "Packaged Frozen Food Merchant Wholesalers " },
  { code: 424430, title: "Dairy Product (except Dried or Canned) Merchant Wholesalers " },
  { code: 424440, title: "Poultry and Poultry Product Merchant Wholesalers " },
  { code: 424450, title: "Confectionery Merchant Wholesalers " },
  { code: 424460, title: "Fish and Seafood Merchant Wholesalers " },
  { code: 424470, title: "Meat and Meat Product Merchant Wholesalers " },
  { code: 424480, title: "Fresh Fruit and Vegetable Merchant Wholesalers " },
  { code: 424490, title: "Other Grocery and Related Products Merchant Wholesalers " },
  { code: 424510, title: "Grain and Field Bean Merchant Wholesalers " },
  { code: 424520, title: "Livestock Merchant Wholesalers " },
  { code: 424590, title: "Other Farm Product Raw Material Merchant Wholesalers " },
  { code: 424610, title: "Plastics Materials and Basic Forms and Shapes Merchant Wholesalers " },
  { code: 424690, title: "Other Chemical and Allied Products Merchant Wholesalers " },
  { code: 424710, title: "Petroleum Bulk Stations and Terminals " },
  { code: 424720, title: "Petroleum and Petroleum Products Merchant Wholesalers (except Bulk Stations and Terminals) " },
  { code: 424810, title: "Beer and Ale Merchant Wholesalers " },
  { code: 424820, title: "Wine and Distilled Alcoholic Beverage Merchant Wholesalers " },
  { code: 424910, title: "Farm Supplies Merchant Wholesalers " },
  { code: 424920, title: "Book, Periodical, and Newspaper Merchant Wholesalers " },
  { code: 424930, title: "Flower, Nursery Stock, and Florists' Supplies Merchant Wholesalers " },
  { code: 424940, title: "Tobacco Product and Electronic Cigarette Merchant Wholesalers " },
  { code: 424950, title: "Paint, Varnish, and Supplies Merchant Wholesalers " },
  { code: 424990, title: "Other Miscellaneous Nondurable Goods Merchant Wholesalers " },
  { code: 425120, title: "Wholesale Trade Agents and Brokers " },
  { code: 441110, title: "New Car Dealers " },
  { code: 441120, title: "Used Car Dealers " },
  { code: 441210, title: "Recreational Vehicle Dealers " },
  { code: 441222, title: "Boat Dealers " },
  { code: 441227, title: "Motorcycle, ATV, and All Other Motor Vehicle Dealers " },
  { code: 441330, title: "Automotive Parts and Accessories Retailers " },
  { code: 441340, title: "Tire Dealers " },
  { code: 444110, title: "Home Centers " },
  { code: 444120, title: "Paint and Wallpaper Retailers " },
  { code: 444140, title: "Hardware Retailers " },
  { code: 444180, title: "Other Building Material Dealers " },
  { code: 444230, title: "Outdoor Power Equipment Retailers " },
  { code: 444240, title: "Nursery, Garden Center, and Farm Supply Retailers " },
  { code: 445110, title: "Supermarkets and Other Grocery Retailers (except Convenience Retailers) " },
  { code: 445131, title: "Convenience Retailers " },
  { code: 445132, title: "Vending Machine Operators " },
  { code: 445230, title: "Fruit and Vegetable Retailers " },
  { code: 445240, title: "Meat Retailers " },
  { code: 445250, title: "Fish and Seafood Retailers " },
  { code: 445291, title: "Baked Goods Retailers " },
  { code: 445292, title: "Confectionery and Nut Retailers " },
  { code: 445298, title: "All Other Specialty Food Retailers " },
  { code: 445320, title: "Beer, Wine, and Liquor Retailers " },
  { code: 449110, title: "Furniture Retailers " },
  { code: 449121, title: "Floor Covering Retailers " },
  { code: 449122, title: "Window Treatment Retailers " },
  { code: 449129, title: "All Other Home Furnishings Retailers " },
  { code: 449210, title: "Electronics and Appliance Retailers" },
  { code: 455110, title: "Department Stores " },
  { code: 455211, title: "Warehouse Clubs and Supercenters " },
  { code: 455219, title: "All Other General Merchandise Retailers " },
  { code: 456110, title: "Pharmacies and Drug Retailers " },
  { code: 456120, title: "Cosmetics, Beauty Supplies, and Perfume Retailers " },
  { code: 456130, title: "Optical Goods Retailers " },
  { code: 456191, title: "Food (Health) Supplement Retailers " },
  { code: 456199, title: "All Other Health and Personal Care Retailers " },
  { code: 457110, title: "Gasoline Stations with Convenience Stores " },
  { code: 457120, title: "Other Gasoline Stations " },
  { code: 457210, title: "Fuel Dealers " },
  { code: 458110, title: "Clothing and Clothing Accessories Retailers " },
  { code: 458210, title: "Shoe Retailers " },
  { code: 458310, title: "Jewelry Retailers " },
  { code: 458320, title: "Luggage and Leather Goods Retailers " },
  { code: 459110, title: "Sporting Goods Retailers " },
  { code: 459120, title: "Hobby, Toy, and Game Retailers " },
  { code: 459130, title: "Sewing, Needlework, and Piece Goods Retailers " },
  { code: 459140, title: "Musical Instrument and Supplies Retailers " },
  { code: 459210, title: "Book Retailers and News Dealers " },
  { code: 459310, title: "Florists " },
  { code: 459410, title: "Office Supplies and Stationery Retailers " },
  { code: 459420, title: "Gift, Novelty, and Souvenir Retailers " },
  { code: 459510, title: "Used Merchandise Retailers " },
  { code: 459910, title: "Pet and Pet Supplies Retailers " },
  { code: 459920, title: "Art Dealers " },
  { code: 459930, title: "Manufactured (Mobile) Home Dealers " },
  { code: 459991, title: "Tobacco, Electronic Cigarette, and Other Smoking Supplies Retailers " },
  { code: 459999, title: "All Other Miscellaneous Retailers " },
  { code: 481111, title: "Scheduled Passenger Air Transportation " },
  { code: 481112, title: "Scheduled Freight Air Transportation " },
  { code: 481211, title: "Nonscheduled Chartered Passenger Air Transportation " },
  { code: 481212, title: "Nonscheduled Chartered Freight Air Transportation " },
  { code: 481219, title: "Other Nonscheduled Air Transportation " },
  { code: 482111, title: "Line-Haul Railroads " },
  { code: 482112, title: "Short Line Railroads " },
  { code: 483111, title: "Deep Sea Freight Transportation " },
  { code: 483112, title: "Deep Sea Passenger Transportation " },
  { code: 483113, title: "Coastal and Great Lakes Freight Transportation " },
  { code: 483114, title: "Coastal and Great Lakes Passenger Transportation " },
  { code: 483211, title: "Inland Water Freight Transportation " },
  { code: 483212, title: "Inland Water Passenger Transportation " },
  { code: 484110, title: "General Freight Trucking, Local " },
  { code: 484121, title: "General Freight Trucking, Long-Distance, Truckload " },
  { code: 484122, title: "General Freight Trucking, Long-Distance, Less Than Truckload " },
  { code: 484210, title: "Used Household and Office Goods Moving" },
  { code: 484220, title: "Specialized Freight (except Used Goods) Trucking, Local " },
  { code: 484230, title: "Specialized Freight (except Used Goods) Trucking, Long-Distance " },
  { code: 485111, title: "Mixed Mode Transit Systems " },
  { code: 485112, title: "Commuter Rail Systems " },
  { code: 485113, title: "Bus and Other Motor Vehicle Transit Systems " },
  { code: 485119, title: "Other Urban Transit Systems " },
  { code: 485210, title: "Interurban and Rural Bus Transportation" },
  { code: 485310, title: "Taxi and Ridesharing Services " },
  { code: 485320, title: "Limousine Service" },
  { code: 485410, title: "School and Employee Bus Transportation" },
  { code: 485510, title: "Charter Bus Industry" },
  { code: 485991, title: "Special Needs Transportation " },
  { code: 485999, title: "All Other Transit and Ground Passenger Transportation " },
  { code: 486110, title: "Pipeline Transportation of Crude Oil" },
  { code: 486210, title: "Pipeline Transportation of Natural Gas" },
  { code: 486910, title: "Pipeline Transportation of Refined Petroleum Products" },
  { code: 486990, title: "All Other Pipeline Transportation" },
  { code: 487110, title: "Scenic and Sightseeing Transportation, Land" },
  { code: 487210, title: "Scenic and Sightseeing Transportation, Water" },
  { code: 487990, title: "Scenic and Sightseeing Transportation, Other" },
  { code: 488111, title: "Air Traffic Control" },
  { code: 488119, title: "Other Airport Operations " },
  { code: 488190, title: "Other Support Activities for Air Transportation" },
  { code: 488210, title: "Support Activities for Rail Transportation" },
  { code: 488310, title: "Port and Harbor Operations" },
  { code: 488320, title: "Marine Cargo Handling" },
  { code: 488330, title: "Navigational Services to Shipping " },
  { code: 488390, title: "Other Support Activities for Water Transportation" },
  { code: 488410, title: "Motor Vehicle Towing" },
  { code: 488490, title: "Other Support Activities for Road Transportation " },
  { code: 488510, title: "Freight Transportation Arrangement " },
  { code: 488991, title: "Packing and Crating " },
  { code: 488999, title: "All Other Support Activities for Transportation " },
  { code: 491110, title: "Postal Service" },
  { code: 492110, title: "Couriers and Express Delivery Services" },
  { code: 492210, title: "Local Messengers and Local Delivery" },
  { code: 493110, title: "General Warehousing and Storage " },
  { code: 493120, title: "Refrigerated Warehousing and Storage" },
  { code: 493130, title: "Farm Product Warehousing and Storage" },
  { code: 493190, title: "Other Warehousing and Storage" },
  { code: 512110, title: "Motion Picture and Video Production " },
  { code: 512120, title: "Motion Picture and Video Distribution" },
  { code: 512131, title: "Motion Picture Theaters (except Drive-Ins) " },
  { code: 512132, title: "Drive-In Motion Picture Theaters " },
  { code: 512191, title: "Teleproduction and Other Postproduction Services " },
  { code: 512199, title: "Other Motion Picture and Video Industries " },
  { code: 512230, title: "Music Publishers" },
  { code: 512240, title: "Sound Recording Studios" },
  { code: 512250, title: "Record Production and Distribution" },
  { code: 512290, title: "Other Sound Recording Industries" },
  { code: 513110, title: "Newspaper Publishers " },
  { code: 513120, title: "Periodical Publishers " },
  { code: 513130, title: "Book Publishers " },
  { code: 513140, title: "Directory and Mailing List Publishers " },
  { code: 513191, title: "Greeting Card Publishers " },
  { code: 513199, title: "All Other Publishers " },
  { code: 513210, title: "Software Publishers" },
  { code: 516110, title: "Radio Broadcasting Stations " },
  { code: 516120, title: "Television Broadcasting Stations " },
  { code: 516210, title: "Media Streaming Distribution Services, Social Networks, and Other Media Networks and Content Providers" },
  { code: 517111, title: "Wired Telecommunications Carriers " },
  { code: 517112, title: "Wireless Telecommunications Carriers (except Satellite)" },
  { code: 517121, title: "Telecommunications Resellers" },
  { code: 517122, title: "Agents for Wireless Telecommunications Services" },
  { code: 517410, title: "Satellite Telecommunications" },
  { code: 517810, title: "All Other Telecommunications " },
  { code: 518210, title: "Computing Infrastructure Providers, Data Processing, Web Hosting, and Related Services" },
  { code: 519210, title: "Libraries and Archives " },
  { code: 519290, title: "Web Search Portals and All Other Information Services" },
  { code: 521110, title: "Monetary Authorities-Central Bank" },
  { code: 522110, title: "Commercial Banking " },
  { code: 522130, title: "Credit Unions " },
  { code: 522180, title: "Savings Institutions and Other Depository Credit Intermediation " },
  { code: 522210, title: "Credit Card Issuing " },
  { code: 522220, title: "Sales Financing " },
  { code: 522291, title: "Consumer Lending " },
  { code: 522292, title: "Real Estate Credit " },
  { code: 522299, title: "International, Secondary Market, and All Other Nondepository Credit Intermediation " },
  { code: 522310, title: "Mortgage and Nonmortgage Loan Brokers " },
  { code: 522320, title: "Financial Transactions Processing, Reserve, and Clearinghouse Activities " },
  { code: 522390, title: "Other Activities Related to Credit Intermediation " },
  { code: 523150, title: "Investment Banking and Securities Intermediation " },
  { code: 523160, title: "Commodity Contracts Intermediation " },
  { code: 523210, title: "Securities and Commodity Exchanges" },
  { code: 523910, title: "Miscellaneous Intermediation " },
  { code: 523940, title: "Portfolio Management and Investment Advice " },
  { code: 523991, title: "Trust, Fiduciary, and Custody Activities " },
  { code: 523999, title: "Miscellaneous Financial Investment Activities " },
  { code: 524113, title: "Direct Life Insurance Carriers " },
  { code: 524114, title: "Direct Health and Medical Insurance Carriers " },
  { code: 524126, title: "Direct Property and Casualty Insurance Carriers " },
  { code: 524127, title: "Direct Title Insurance Carriers " },
  { code: 524128, title: "Other Direct Insurance (except Life, Health, and Medical) Carriers " },
  { code: 524130, title: "Reinsurance Carriers " },
  { code: 524210, title: "Insurance Agencies and Brokerages " },
  { code: 524291, title: "Claims Adjusting " },
  { code: 524292, title: "Pharmacy Benefit Management and Other Third Party Administration of Insurance and Pension Funds " },
  { code: 524298, title: "All Other Insurance Related Activities " },
  { code: 525110, title: "Pension Funds " },
  { code: 525120, title: "Health and Welfare Funds " },
  { code: 525190, title: "Other Insurance Funds " },
  { code: 525910, title: "Open-End Investment Funds " },
  { code: 525920, title: "Trusts, Estates, and Agency Accounts " },
  { code: 525990, title: "Other Financial Vehicles " },
  { code: 531110, title: "Lessors of Residential Buildings and Dwellings " },
  { code: 531120, title: "Lessors of Nonresidential Buildings (except Miniwarehouses) " },
  { code: 531130, title: "Lessors of Miniwarehouses and Self-Storage Units " },
  { code: 531190, title: "Lessors of Other Real Estate Property " },
  { code: 531210, title: "Offices of Real Estate Agents and Brokers" },
  { code: 531311, title: "Residential Property Managers " },
  { code: 531312, title: "Nonresidential Property Managers " },
  { code: 531320, title: "Offices of Real Estate Appraisers " },
  { code: 531390, title: "Other Activities Related to Real Estate " },
  { code: 532111, title: "Passenger Car Rental " },
  { code: 532112, title: "Passenger Car Leasing " },
  { code: 532120, title: "Truck, Utility Trailer, and RV (Recreational Vehicle) Rental and Leasing " },
  { code: 532210, title: "Consumer Electronics and Appliances Rental" },
  { code: 532281, title: "Formal Wear and Costume Rental" },
  { code: 532282, title: "Video Tape and Disc Rental" },
  { code: 532283, title: "Home Health Equipment Rental " },
  { code: 532284, title: "Recreational Goods Rental " },
  { code: 532289, title: "All Other Consumer Goods Rental " },
  { code: 532310, title: "General Rental Centers" },
  { code: 532411, title: "Commercial Air, Rail, and Water Transportation Equipment Rental and Leasing " },
  { code: 532412, title: "Construction, Mining, and Forestry Machinery and Equipment Rental and Leasing " },
  { code: 532420, title: "Office Machinery and Equipment Rental and Leasing" },
  { code: 532490, title: "Other Commercial and Industrial Machinery and Equipment Rental and Leasing " },
  { code: 533110, title: "Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)" },
  { code: 541110, title: "Offices of Lawyers" },
  { code: 541120, title: "Offices of Notaries" },
  { code: 541191, title: "Title Abstract and Settlement Offices " },
  { code: 541199, title: "All Other Legal Services " },
  { code: 541211, title: "Offices of Certified Public Accountants " },
  { code: 541213, title: "Tax Preparation Services " },
  { code: 541214, title: "Payroll Services " },
  { code: 541219, title: "Other Accounting Services " },
  { code: 541310, title: "Architectural Services" },
  { code: 541320, title: "Landscape Architectural Services" },
  { code: 541330, title: "Engineering Services" },
  { code: 541340, title: "Drafting Services" },
  { code: 541350, title: "Building Inspection Services" },
  { code: 541360, title: "Geophysical Surveying and Mapping Services" },
  { code: 541370, title: "Surveying and Mapping (except Geophysical) Services" },
  { code: 541380, title: "Testing Laboratories and Services" },
  { code: 541410, title: "Interior Design Services" },
  { code: 541420, title: "Industrial Design Services" },
  { code: 541430, title: "Graphic Design Services" },
  { code: 541490, title: "Other Specialized Design Services" },
  { code: 541511, title: "Custom Computer Programming Services " },
  { code: 541512, title: "Computer Systems Design Services " },
  { code: 541513, title: "Computer Facilities Management Services " },
  { code: 541519, title: "Other Computer Related Services" },
  { code: 541611, title: "Administrative Management and General Management Consulting Services " },
  { code: 541612, title: "Human Resources Consulting Services " },
  { code: 541613, title: "Marketing Consulting Services " },
  { code: 541614, title: "Process, Physical Distribution, and Logistics Consulting Services " },
  { code: 541618, title: "Other Management Consulting Services " },
  { code: 541620, title: "Environmental Consulting Services" },
  { code: 541690, title: "Other Scientific and Technical Consulting Services" },
  { code: 541713, title: "Research and Development in Nanotechnology " },
  { code: 541714, title: "Research and Development in Biotechnology (except Nanobiotechnology)" },
  { code: 541715, title: "Research and Development in the Physical, Engineering, and Life Sciences (except Nanotechnology and Biotechnology) " },
  { code: 541720, title: "Research and Development in the Social Sciences and Humanities " },
  { code: 541810, title: "Advertising Agencies" },
  { code: 541820, title: "Public Relations Agencies" },
  { code: 541830, title: "Media Buying Agencies" },
  { code: 541840, title: "Media Representatives" },
  { code: 541850, title: "Indoor and Outdoor Display Advertising" },
  { code: 541860, title: "Direct Mail Advertising" },
  { code: 541870, title: "Advertising Material Distribution Services" },
  { code: 541890, title: "Other Services Related to Advertising " },
  { code: 541910, title: "Marketing Research and Public Opinion Polling" },
  { code: 541921, title: "Photography Studios, Portrait " },
  { code: 541922, title: "Commercial Photography " },
  { code: 541930, title: "Translation and Interpretation Services" },
  { code: 541940, title: "Veterinary Services " },
  { code: 541990, title: "All Other Professional, Scientific, and Technical Services" },
  { code: 551111, title: "Offices of Bank Holding Companies " },
  { code: 551112, title: "Offices of Other Holding Companies " },
  { code: 551114, title: "Corporate, Subsidiary, and Regional Managing Offices " },
  { code: 561110, title: "Office Administrative Services" },
  { code: 561210, title: "Facilities Support Services" },
  { code: 561311, title: "Employment Placement Agencies " },
  { code: 561312, title: "Executive Search Services " },
  { code: 561320, title: "Temporary Help Services" },
  { code: 561330, title: "Professional Employer Organizations" },
  { code: 561410, title: "Document Preparation Services" },
  { code: 561421, title: "Telephone Answering Services " },
  { code: 561422, title: "Telemarketing Bureaus and Other Contact Centers " },
  { code: 561431, title: "Private Mail Centers " },
  { code: 561439, title: "Other Business Service Centers (including Copy Shops) " },
  { code: 561440, title: "Collection Agencies" },
  { code: 561450, title: "Credit Bureaus" },
  { code: 561491, title: "Repossession Services " },
  { code: 561492, title: "Court Reporting and Stenotype Services " },
  { code: 561499, title: "All Other Business Support Services " },
  { code: 561510, title: "Travel Agencies" },
  { code: 561520, title: "Tour Operators" },
  { code: 561591, title: "Convention and Visitors Bureaus " },
  { code: 561599, title: "All Other Travel Arrangement and Reservation Services " },
  { code: 561611, title: "Investigation and Personal Background Check Services " },
  { code: 561612, title: "Security Guards and Patrol Services " },
  { code: 561613, title: "Armored Car Services " },
  { code: 561621, title: "Security Systems Services (except Locksmiths) " },
  { code: 561622, title: "Locksmiths " },
  { code: 561710, title: "Exterminating and Pest Control Services" },
  { code: 561720, title: "Janitorial Services " },
  { code: 561730, title: "Landscaping Services" },
  { code: 561740, title: "Carpet and Upholstery Cleaning Services" },
  { code: 561790, title: "Other Services to Buildings and Dwellings " },
  { code: 561910, title: "Packaging and Labeling Services" },
  { code: 561920, title: "Convention and Trade Show Organizers" },
  { code: 561990, title: "All Other Support Services" },
  { code: 562111, title: "Solid Waste Collection " },
  { code: 562112, title: "Hazardous Waste Collection " },
  { code: 562119, title: "Other Waste Collection " },
  { code: 562211, title: "Hazardous Waste Treatment and Disposal " },
  { code: 562212, title: "Solid Waste Landfill " },
  { code: 562213, title: "Solid Waste Combustors and Incinerators " },
  { code: 562219, title: "Other Nonhazardous Waste Treatment and Disposal " },
  { code: 562910, title: "Remediation Services " },
  { code: 562920, title: "Materials Recovery Facilities " },
  { code: 562991, title: "Septic Tank and Related Services " },
  { code: 562998, title: "All Other Miscellaneous Waste Management Services " },
  { code: 611110, title: "Elementary and Secondary Schools " },
  { code: 611210, title: "Junior Colleges " },
  { code: 611310, title: "Colleges, Universities, and Professional Schools " },
  { code: 611410, title: "Business and Secretarial Schools " },
  { code: 611420, title: "Computer Training " },
  { code: 611430, title: "Professional and Management Development Training " },
  { code: 611511, title: "Cosmetology and Barber Schools " },
  { code: 611512, title: "Flight Training " },
  { code: 611513, title: "Apprenticeship Training " },
  { code: 611519, title: "Other Technical and Trade Schools " },
  { code: 611610, title: "Fine Arts Schools " },
  { code: 611620, title: "Sports and Recreation Instruction " },
  { code: 611630, title: "Language Schools " },
  { code: 611691, title: "Exam Preparation and Tutoring " },
  { code: 611692, title: "Automobile Driving Schools " },
  { code: 611699, title: "All Other Miscellaneous Schools and Instruction " },
  { code: 611710, title: "Educational Support Services" },
  { code: 621111, title: "Offices of Physicians (except Mental Health Specialists) " },
  { code: 621112, title: "Offices of Physicians, Mental Health Specialists " },
  { code: 621210, title: "Offices of Dentists " },
  { code: 621310, title: "Offices of Chiropractors " },
  { code: 621320, title: "Offices of Optometrists" },
  { code: 621330, title: "Offices of Mental Health Practitioners (except Physicians) " },
  { code: 621340, title: "Offices of Physical, Occupational and Speech Therapists, and Audiologists " },
  { code: 621391, title: "Offices of Podiatrists " },
  { code: 621399, title: "Offices of All Other Miscellaneous Health Practitioners " },
  { code: 621410, title: "Family Planning Centers " },
  { code: 621420, title: "Outpatient Mental Health and Substance Abuse Centers " },
  { code: 621491, title: "HMO Medical Centers " },
  { code: 621492, title: "Kidney Dialysis Centers " },
  { code: 621493, title: "Freestanding Ambulatory Surgical and Emergency Centers " },
  { code: 621498, title: "All Other Outpatient Care Centers " },
  { code: 621511, title: "Medical Laboratories " },
  { code: 621512, title: "Diagnostic Imaging Centers " },
  { code: 621610, title: "Home Health Care Services" },
  { code: 621910, title: "Ambulance Services " },
  { code: 621991, title: "Blood and Organ Banks " },
  { code: 621999, title: "All Other Miscellaneous Ambulatory Health Care Services " },
  { code: 622110, title: "General Medical and Surgical Hospitals " },
  { code: 622210, title: "Psychiatric and Substance Abuse Hospitals " },
  { code: 622310, title: "Specialty (except Psychiatric and Substance Abuse) Hospitals " },
  { code: 623110, title: "Nursing Care Facilities (Skilled Nursing Facilities) " },
  { code: 623210, title: "Residential Intellectual and Developmental Disability Facilities " },
  { code: 623220, title: "Residential Mental Health and Substance Abuse Facilities " },
  { code: 623311, title: "Continuing Care Retirement Communities " },
  { code: 623312, title: "Assisted Living Facilities for the Elderly " },
  { code: 623990, title: "Other Residential Care Facilities " },
  { code: 624110, title: "Child and Youth Services " },
  { code: 624120, title: "Services for the Elderly and Persons with Disabilities " },
  { code: 624190, title: "Other Individual and Family Services " },
  { code: 624210, title: "Community Food Services " },
  { code: 624221, title: "Temporary Shelters " },
  { code: 624229, title: "Other Community Housing Services " },
  { code: 624230, title: "Emergency and Other Relief Services " },
  { code: 624310, title: "Vocational Rehabilitation Services " },
  { code: 624410, title: "Child Care Services " },
  { code: 711110, title: "Theater Companies and Dinner Theaters " },
  { code: 711120, title: "Dance Companies " },
  { code: 711130, title: "Musical Groups and Artists " },
  { code: 711190, title: "Other Performing Arts Companies " },
  { code: 711211, title: "Sports Teams and Clubs " },
  { code: 711212, title: "Racetracks " },
  { code: 711219, title: "Other Spectator Sports " },
  { code: 711310, title: "Promoters of Performing Arts, Sports, and Similar Events with Facilities " },
  { code: 711320, title: "Promoters of Performing Arts, Sports, and Similar Events without Facilities " },
  { code: 711410, title: "Agents and Managers for Artists, Athletes, Entertainers, and Other Public Figures" },
  { code: 711510, title: "Independent Artists, Writers, and Performers " },
  { code: 712110, title: "Museums " },
  { code: 712120, title: "Historical Sites" },
  { code: 712130, title: "Zoos and Botanical Gardens " },
  { code: 712190, title: "Nature Parks and Other Similar Institutions" },
  { code: 713110, title: "Amusement and Theme Parks " },
  { code: 713120, title: "Amusement Arcades" },
  { code: 713210, title: "Casinos (except Casino Hotels)" },
  { code: 713290, title: "Other Gambling Industries " },
  { code: 713910, title: "Golf Courses and Country Clubs" },
  { code: 713920, title: "Skiing Facilities" },
  { code: 713930, title: "Marinas" },
  { code: 713940, title: "Fitness and Recreational Sports Centers " },
  { code: 713950, title: "Bowling Centers" },
  { code: 713990, title: "All Other Amusement and Recreation Industries " },
  { code: 721110, title: "Hotels (except Casino Hotels) and Motels " },
  { code: 721120, title: "Casino Hotels" },
  { code: 721191, title: "Bed-and-Breakfast Inns " },
  { code: 721199, title: "All Other Traveler Accommodation " },
  { code: 721211, title: "RV (Recreational Vehicle) Parks and Campgrounds " },
  { code: 721214, title: "Recreational and Vacation Camps (except Campgrounds) " },
  { code: 721310, title: "Rooming and Boarding Houses, Dormitories, and Workers' Camps " },
  { code: 722310, title: "Food Service Contractors" },
  { code: 722320, title: "Caterers" },
  { code: 722330, title: "Mobile Food Services" },
  { code: 722410, title: "Drinking Places (Alcoholic Beverages) " },
  { code: 722511, title: "Full-Service Restaurants " },
  { code: 722513, title: "Limited-Service Restaurants " },
  { code: 722514, title: "Cafeterias, Grill Buffets, and Buffets " },
  { code: 722515, title: "Snack and Nonalcoholic Beverage Bars " },
  { code: 811111, title: "General Automotive Repair " },
  { code: 811114, title: "Specialized Automotive Repair " },
  { code: 811121, title: "Automotive Body, Paint, and Interior Repair and Maintenance " },
  { code: 811122, title: "Automotive Glass Replacement Shops " },
  { code: 811191, title: "Automotive Oil Change and Lubrication Shops " },
  { code: 811192, title: "Car Washes " },
  { code: 811198, title: "All Other Automotive Repair and Maintenance " },
  { code: 811210, title: "Electronic and Precision Equipment Repair and Maintenance " },
  { code: 811310, title: "Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance " },
  { code: 811411, title: "Home and Garden Equipment Repair and Maintenance " },
  { code: 811412, title: "Appliance Repair and Maintenance " },
  { code: 811420, title: "Reupholstery and Furniture Repair" },
  { code: 811430, title: "Footwear and Leather Goods Repair" },
  { code: 811490, title: "Other Personal and Household Goods Repair and Maintenance " },
  { code: 812111, title: "Barber Shops " },
  { code: 812112, title: "Beauty Salons " },
  { code: 812113, title: "Nail Salons " },
  { code: 812191, title: "Diet and Weight Reducing Centers " },
  { code: 812199, title: "Other Personal Care Services " },
  { code: 812210, title: "Funeral Homes and Funeral Services " },
  { code: 812220, title: "Cemeteries and Crematories " },
  { code: 812310, title: "Coin-Operated Laundries and Drycleaners " },
  { code: 812320, title: "Drycleaning and Laundry Services (except Coin-Operated) " },
  { code: 812331, title: "Linen Supply " },
  { code: 812332, title: "Industrial Launderers " },
  { code: 812910, title: "Pet Care (except Veterinary) Services " },
  { code: 812921, title: "Photofinishing Laboratories (except One-Hour) " },
  { code: 812922, title: "One-Hour Photofinishing " },
  { code: 812930, title: "Parking Lots and Garages " },
  { code: 812990, title: "All Other Personal Services " },
  { code: 813110, title: "Religious Organizations " },
  { code: 813211, title: "Grantmaking Foundations " },
  { code: 813212, title: "Voluntary Health Organizations " },
  { code: 813219, title: "Other Grantmaking and Giving Services " },
  { code: 813311, title: "Human Rights Organizations " },
  { code: 813312, title: "Environment, Conservation and Wildlife Organizations " },
  { code: 813319, title: "Other Social Advocacy Organizations " },
  { code: 813410, title: "Civic and Social Organizations " },
  { code: 813910, title: "Business Associations " },
  { code: 813920, title: "Professional Organizations " },
  { code: 813930, title: "Labor Unions and Similar Labor Organizations " },
  { code: 813940, title: "Political Organizations " },
  { code: 813990, title: "Other Similar Organizations (except Business, Professional, Labor, and Political Organizations) " },
  { code: 814110, title: "Private Households" },
  { code: 921110, title: "Executive Offices " },
  { code: 921120, title: "Legislative Bodies " },
  { code: 921130, title: "Public Finance Activities " },
  { code: 921140, title: "Executive and Legislative Offices, Combined " },
  { code: 921150, title: "American Indian and Alaska Native Tribal Governments " },
  { code: 921190, title: "Other General Government Support " },
  { code: 922110, title: "Courts " },
  { code: 922120, title: "Police Protection " },
  { code: 922130, title: "Legal Counsel and Prosecution " },
  { code: 922140, title: "Correctional Institutions " },
  { code: 922150, title: "Parole Offices and Probation Offices " },
  { code: 922160, title: "Fire Protection " },
  { code: 922190, title: "Other Justice, Public Order, and Safety Activities " },
  { code: 923110, title: "Administration of Education Programs " },
  { code: 923120, title: "Administration of Public Health Programs " },
  { code: 923130, title: "Administration of Human Resource Programs (except Education, Public Health, and Veterans' Affairs Programs)" },
  { code: 923140, title: "Administration of Veterans' Affairs"},
  { code: 924110, title: "Administration of Air and Water Resource and Solid Waste Management Programs " },
  { code: 924120, title: "Administration of Conservation Programs " },
  { code: 925110, title: "Administration of Housing Programs " },
  { code: 925120, title: "Administration of Urban Planning and Community and Rural Development " },
  { code: 926110, title: "Administration of General Economic Programs " },
  { code: 926120, title: "Regulation and Administration of Transportation Programs " },
  { code: 926130, title: "Regulation and Administration of Communications, Electric, Gas, and Other Utilities " },
  { code: 926140, title: "Regulation of Agricultural Marketing and Commodities " },
  { code: 926150, title: "Regulation, Licensing, and Inspection of Miscellaneous Commercial Sectors " },
  { code: 927110, title: "Space Research and Technology " },
  { code: 928110, title: "National Security " },
  { code: 928120, title: "International Affairs " },
];

export default naicsOptions;