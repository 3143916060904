import React, { useState, useEffect } from 'react';
import {
  Box, Container, Typography, Modal, CircularProgress
} from '@mui/material';
import { Widget } from '@typeform/embed-react';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../utilities/api';

const style = {
  width: '100%',
  height: '80vh',
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 24,
  p: 3,
  overflowY: 'auto',
};

const modalStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};


const loadingStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: '80vh',
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 24,
  p: 3,
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};

const PreferencesPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [userId, setUserId] = useState(null);
  const api = useApi();


  useEffect(() => {
    const fetchUserId = async () => {
      try {
        const response = await api.get('auth/users/me/');

        setUserId(response.data.id);
      } catch (error) {
        console.error('Error fetching user ID:', error);
      }
    };

    fetchUserId();
  }, []);


  const handleFormSubmit = () => {
    console.log('Form submitted');
    setIsLoading(true);

    // Display loading modal for 10 seconds, then navigate to /home
    setTimeout(() => {
      console.log('Navigate to /home');
      navigate('/home');
    }, 10000);
  };


  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 4 }}>
        <Box sx={style}>
          <Widget
            id='GXS5t2mD' // Replace with your Typeform ID
            style={{ width: '100%', height: '100%' }}
            hidden={{ user_id: userId }}
            onSubmit={handleFormSubmit}
            className="my-form"
          />
        </Box>
      </Box>
      <Modal
        open={isLoading}
        aria-labelledby="loading-modal"
        aria-describedby="loading-modal-description"
        sx={modalStyle}
      >
        <Box sx={loadingStyle}>
          <CircularProgress />
          <Typography variant='h6'>One moment while we gather your first leads...</Typography>
        </Box>
      </Modal>
    </Container>
  );
};

export default PreferencesPage;
