import React from 'react';
import { createRoot } from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { SubscriptionProvider } from './utilities/SubscriptionContext';
import App from './App';
import './index.css';

import { LicenseInfo } from '@mui/x-license';
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);

const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN;
const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const auth0RedirectUri = process.env.REACT_APP_AUTH0_CALLBACK_URL || window.location.origin + "/home";
const auth0Audience = process.env.REACT_APP_AUTH0_AUDIENCE;

const container = document.getElementById('root');
const root = createRoot(container);

const theme = createTheme({
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      },
    },
  },
});

root.render(
  <Auth0Provider
    domain={auth0Domain}
    clientId={auth0ClientId}
    authorizationParams={{
      redirect_uri: auth0RedirectUri,
      audience: auth0Audience,
    }}
    cacheLocation="localstorage" // Ensure sessions persist across page reloads
    useRefreshTokens={true} // Use refresh tokens to maintain a long-lived session
    scope="read:current_user update:current_user_metadata"
  >
    <SubscriptionProvider>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </SubscriptionProvider>
  </Auth0Provider>
);
