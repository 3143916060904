import React, { useState } from 'react';
import { Box, Card, CardContent, Container, Typography, Button, CircularProgress, useTheme } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BillingIcon from '@mui/icons-material/Receipt';
import { useApi } from '../utilities/api';
import { useNavigate } from 'react-router-dom';

const Account = () => {
  const theme = useTheme();
  const api = useApi();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleCustomerPortal = async () => {
    setLoading(true);
    try {
      const response = await api.post('/api/create-customer-portal-session', {});
      window.location.href = response.data.url;
    } catch (error) {
      console.error('Error creating customer portal session:', error);
      setLoading(false);
    }
  };

  const handleClickPreferences = () => {
    navigate('/preferences');
  }

  return (
    <Container maxWidth="md" sx={{ py: 5 }}>
      <Box sx={{ flexGrow: 1 }}>
        <Card sx={{ mb: 4, boxShadow: 3 }}>
          <CardContent>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <AccountCircleIcon sx={{ fontSize: 48, color: theme.palette.primary.main, mr: 2 }} />
              <Typography variant="h4" component="div">Account Management</Typography>
            </Box>
            <Typography variant="body1" color="textSecondary">Access your billing details and manage your subscription.</Typography>
          </CardContent>
        </Card>

        <Card sx={{ mb: 4, boxShadow: 3 }}>
          <CardContent>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <AccountCircleIcon sx={{ fontSize: 36, color: theme.palette.secondary.main, mr: 2 }} />
              <Typography variant="h5" component="div">Update Profile Preferences</Typography>
            </Box>
            <Typography variant="body1" gutterBottom>Adjust your profile and lending preferences.</Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleClickPreferences}
              startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}
            >
              Preferences
            </Button>
          </CardContent>
        </Card>

        <Card sx={{ mb: 4, boxShadow: 3 }}>
          <CardContent>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <BillingIcon sx={{ fontSize: 36, color: theme.palette.secondary.main, mr: 2 }} />
              <Typography variant="h5" component="div">Billing</Typography>
            </Box>
            <Typography variant="body1" gutterBottom>Manage your billing details and subscriptions via the customer portal.</Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCustomerPortal}
              disabled={loading}
              startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}
            >
              {loading ? 'Loading...' : 'Launch Customer Portal'}
            </Button>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

export default Account;
