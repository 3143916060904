// src/components/AboutTab.js
import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Divider,
  Box,
  Avatar,
  Link,
} from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import PhoneIcon from '@mui/icons-material/Phone';
import PlaceIcon from '@mui/icons-material/Place';
import LanguageIcon from '@mui/icons-material/Language';
import InfoIcon from '@mui/icons-material/Info';
import parsePhoneNumber from "libphonenumber-js";
import { useOutletContext } from 'react-router-dom';

const AboutTabScreen = () => {
  const { profile, description } = useOutletContext();

  const ContactInfoCard = () => (
    <Card variant="outlined" sx={{ mb: 3, boxShadow: 3, borderRadius: 1 }}>
      <CardContent>
        <Box display="flex" alignItems="center" mb={2}>
          <Avatar sx={{ bgcolor: 'primary.main' }}>
            <BusinessIcon />
          </Avatar>
          <Typography variant="h6" sx={{ ml: 2 }}>
            Contact Information
          </Typography>
        </Box>
        <Divider />
        <Box mt={2}>
          <Box display="flex" alignItems="center" mb={2}>
            <PlaceIcon color="primary" />
            {profile.street_address && profile.city && profile.state && profile.zip ? (
              <Typography variant="body2" sx={{ ml: 1 }}>
                {profile?.street_address}, {profile?.city}, {profile?.state} {profile?.zip}
              </Typography>
            ) : (
              <Typography variant="body2" sx={{ ml: 1 }}>Address not available</Typography>
              )}
          </Box>
          <Box display="flex" alignItems="center" mb={2}>
            <PhoneIcon color="primary" />
            {profile?.company_details?.phone ? (
              <Typography variant="body2" sx={{ ml: 1 }}>
                Phone: {parsePhoneNumber(profile?.company_details?.phone).formatNational()}
              </Typography>
            ) : (
              <Typography variant="body2" sx={{ ml: 1 }}>Phone number not available</Typography>
            )}
          </Box>
          <Box display="flex" alignItems="center" mb={2}>
            <LanguageIcon color="primary" />
            {profile?.company_details?.website ? (
              <Typography variant="body2" sx={{ ml: 1 }}>
                Website:{' '}
                <Link href={profile?.company_details.website} target="_blank" rel="noopener">
                  {profile?.company_details.website}
                </Link>
              </Typography>
            ) : (
              <Typography variant="body2" sx={{ ml: 1 }}>Website not available</Typography>
            )}

          </Box>
        </Box>
      </CardContent>
    </Card>
  );

  const OverviewCard = () => (
    <Card variant="outlined" sx={{ mb: 3, boxShadow: 3, borderRadius: 1 }}>
      <CardContent>
        <Box display="flex" alignItems="center" mb={2}>
          <Avatar sx={{ bgcolor: 'primary.main' }}>
            <InfoIcon />
          </Avatar>
          <Typography variant="h6" sx={{ ml: 2 }}>
            Overview
          </Typography>
        </Box>
        <Divider />
        <Box mt={2}>
          <Typography variant="h6" gutterBottom>
            Company Description
          </Typography>
          <Typography variant="body2">{description}</Typography>
        </Box>
      </CardContent>
    </Card>
  );

  return (
    <Box>
      <ContactInfoCard />
      <OverviewCard />
    </Box>
  );
};

export default AboutTabScreen;
