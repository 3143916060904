import React, { useState, useEffect } from 'react';
import {
  Card, CardContent, Container, Box, Typography, Button, Grid, Paper, Chip, Tooltip,
} from '@mui/material';
import { DataGridPro, GridToolbarContainer, GridToolbarExport, GRID_CHECKBOX_SELECTION_COL_DEF, } from '@mui/x-data-grid-pro';
import { useApi } from '../utilities/api';
import EventIcon from '@mui/icons-material/Event';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import StarsIcon from '@mui/icons-material/Stars';
import AssignmentIcon from '@mui/icons-material/Assignment';
import UpdateIcon from '@mui/icons-material/Update';
import CancelIcon from '@mui/icons-material/Cancel';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useNavigate } from 'react-router-dom';
import { PrettyOrgName } from "../utilities/Utilities";
import { format, parse, formatDistanceToNow } from "date-fns";


const SearchResults = ({
  results, setResults, page, setPage, paginationModel, setPaginationModel, changePage, pageSize, setPageSize, rowCount, sortingModel, setSortingModel, isLoading, setIsLoading
}) => {
  const navigate = useNavigate();
  const api = useApi();

  const formatDate = (dateString) => dateString ? format(parse(dateString, 'yyyyMMdd', new Date()), 'MMM d, yyyy') : '--';
  const formatEventDate = (dateString) => {
    if (dateString === null) return '--';
    const parsedDate = parse(dateString, 'yyyy-MM-dd', new Date());
    return formatDistanceToNow(parsedDate, { addSuffix: true });
  };

  const updatePage = (newPage) => {
    console.log('updatePage:', newPage);
  }

  const handleCompanyProfile = (companyId) => {
    navigate(`/profile/${companyId}/home`);
  };

  const toggleFollow = async (company) => {
    try {
      const url = `/api/profiles/${company.id}/follow`;
      const method = company.is_following ? 'delete' : 'post';

      setResults((prevCompanies) =>
        prevCompanies.map((item) =>
          item.id === company.id ? { ...item, is_following: !item.is_following } : item
        )
      );

      await api[method](url);
    } catch (error) {
      console.error('Error changing follow status:', error);
      setResults((prevCompanies) =>
        prevCompanies.map((item) =>
          item.id === company.id ? { ...item, is_following: !item.is_following } : item
        )
      );
    }
  };

  const getFilingTypeChip = (filingType) => {
    switch (filingType) {
      case 'MR':
        return <Chip icon={<AssignmentIcon />} label="Master Record" variant="outlined" size="small" sx={{ width: '140px', px: 1, fontWeight: 'bold', fontSize: '0.75rem' }} />;
      case 'C':
        return <Chip icon={<UpdateIcon />} label="Continuation" variant="outlined" size="small" sx={{ width: '140px', px: 1, fontWeight: 'bold', fontSize: '0.75rem' }} />;
      case 'T':
        return <Chip icon={<CancelIcon />} label="Termination" variant="outlined" size="small" sx={{ width: '140px', px: 1, fontWeight: 'bold', fontSize: '0.75rem' }} />;
      default:
        return <Chip icon={<HelpOutlineIcon />} label="Other" variant="outlined" size="small" sx={{ width: '140px', px: 1, fontWeight: 'bold', fontSize: '0.75rem' }} />;
    }
  };

  const CustomGridToolbar = () => {
    return (
      <GridToolbarContainer>
        <Box sx={{ flexGrow: 1 }} />
        <GridToolbarExport
          slotProps={{
            tooltip: { title: 'Export data' },
          }}
        />
      </GridToolbarContainer>
    );
  }

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      align: 'left',
      headerAlign: 'center',
      width: 340,
      renderCell: (params) => (
        <Box
          display="flex"
          alignItems="center"
          height="100%"
          width="100%"
          sx={{ cursor: 'pointer', color: '#007bff', px: 2 }}
          onClick={() => handleCompanyProfile(params.row.id)}
        >
          <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '0.75rem' }}>
            {PrettyOrgName(params.row)}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'is_following',
      headerName: 'Actions',
      width: 160,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        const hitIt = (row) => {
          toggleFollow(row);
        };
        return (
          <Button
            variant="outlined"
            size="small"
            color='primary'
            sx={{ fontWeight: 'bold', fontSize: '0.75rem', textTransform: 'none' }}
            onClick={(e) => {
              e.stopPropagation();
              hitIt(params.row);
            }}
          >
            {params.value ? 'Following' : '+ Follow'}
          </Button>
        );
      }
    },
    {
      field: 'industry',
      headerName: 'Industry',
      width: 280,
      align: 'center',
      headerAlign: 'center',
      sortComparator: (v1, v2, param1, param2) => {
        const a = param1.api.getRow(param1.id).industry;
        const b = param2.api.getRow(param2.id).industry;
        return a.localeCompare(b);
      },
      renderCell: (params) => {
        const industry = params.row.industry;
        return (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            height="100%"
            width="100%"
            sx={{ cursor: 'pointer', px: 2 }}
          >
            <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '0.75rem', textTransform: 'titleCase' }}>
              {industry !== null ? industry : '--'}
            </Typography>
          </Box>
        )
      }
    },
    {
      field: 'location_city',
      headerName: 'City',
      width: 100,
      align: 'center',
      headerAlign: 'center',
      sortComparator: (v1, v2, param1, param2) => {
        const a = param1.api.getRow(param1.id).location.city;
        const b = param2.api.getRow(param2.id).location.city;
        return a.localeCompare(b);
      },
      renderCell: (params) => {
        const companyLocation = params.row.location;
        return (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            height="100%"
            width="100%"
            sx={{ cursor: 'pointer', px: 2 }}
          >
            <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '0.75rem', textTransform: 'titleCase' }}>
              {companyLocation.city !== null ? companyLocation.city : '--'}
            </Typography>
          </Box>
        )
      }
    },
    {
      field: 'location_state',
      headerName: 'State',
      width: 100,
      align: 'center',
      headerAlign: 'center',
      sortComparator: (v1, v2, param1, param2) => {
        const a = param1.api.getRow(param1.id).location.state;
        const b = param2.api.getRow(param2.id).location.state;
        return a.localeCompare(b);
      },
      renderCell: (params) => {
        const companyLocation = params.row.location;
        return (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            height="100%"
            width="100%"
            sx={{ cursor: 'pointer', px: 2 }}
          >
            <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '0.75rem' }}>
              {companyLocation.state !== null ? companyLocation.state : '--'}
            </Typography>
          </Box>
        )
      }
    },
    {
      field: 'loan_count',
      headerName: 'Total Loans',
      width: 120,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        const loanCount = params.row.loan_count;
        return (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            height="100%"
            width="100%"
            sx={{ cursor: 'pointer', px: 2 }}
          >
            <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '0.75rem' }}>
              {loanCount}
            </Typography>
          </Box>
        )
      }
    },
  ];

  const rows = results.map((company) => ({
    id: company.id,
    industry: company.industry,
    location: company.location,
    name: PrettyOrgName(company),
    loan_count: company.loan_count,
    is_following: company.is_following,
  }));

  return (
    <Container maxWidth={false}>
      <Box position="relative">
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Box mb={2}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={3}>
                      <Box border={1} borderColor="grey.400" borderRadius={1} p={1} textAlign="center">
                        <Typography variant="subtitle2" sx={{ fontWeight: 'bold', fontSize: '0.75rem' }}>{ rowCount } Search Results</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={{ width: '100%' }}>
                  <Paper style={{ width: '100%' }}>
                    <DataGridPro
                      autoHeight
                      pagination
                      pageSizeOptions={[25, 50, 100]}
                      rows={rows}
                      columns={columns}
                      rowCount={rowCount}
                      paginationModel={paginationModel}
                      onPaginationModelChange={(model) => setPaginationModel(model)}
                      paginationMode="server"
                      sortingMode="server"
                      onSortModelChange={(model) => setSortingModel(model)}
                      loading={isLoading}
                      checkboxSelection
                      disablecheckboxSelection
                      components={{
                        Toolbar: CustomGridToolbar,
                      }}

                      sx={{
                        overflowX: 'scroll',
                        '.MuiDataGrid-cell': { paddingX: 0 },
                        '.MuiDataGrid-columnHeaderTitle': { fontWeight: 'bold', fontSize: '0.75rem' },
                        '& .MuiDataGrid-columnHeaders': {
                          borderBottom: '1px solid #007bff',
                        },
                        '& .MuiDataGrid-columnHeader': {
                          backgroundColor: '#f4f4f4',
                          borderBottom: '1px solid #007bff',
                        },
                      }}
                      disableRowSelectionOnClick
                      initialState={{
                        pinnedColumns: { left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'name'] },
                        density: 'standard'
                      }}
                    />
                  </Paper>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default SearchResults;
