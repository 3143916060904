import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

// Reusable API Hook
export const useApi = () => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const apiInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL || 'http://localhost:8000',
  });

  apiInstance.interceptors.request.use(async (config) => {
    try {
      const token = await getAccessTokenSilently({
        audience: 'https://loanscout.us.auth0.com/api/v2/',
        scope: 'openid profile email offline_access',
      });
      config.headers.Authorization = `Bearer ${token}`;
    } catch (e) {
      console.error('Failed to get access token', e);
      await loginWithRedirect();
    }
    return config;
  });

  // Redirect to the account management page if the user receives a 403 error
  apiInstance.interceptors.response.use(
    response => response,
    error => {
      if (error.response && error.response.status === 403) {
        // Redirect to the account management page
        window.location.href = '/account';
      }
      return Promise.reject(error);
    }
  );

  return apiInstance;
};
