import React, { useState, useEffect, useRef } from 'react';
import {
  Box, Modal, Typography, Button, IconButton, CircularProgress, TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useApi } from '../utilities/api';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  height: '70vh',
  bgcolor: 'background.paper',
  border: '1x solid #000',
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
};

const EmailModal = ({ open, handleClose, companyId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const api = useApi();
  const hasFetched = useRef(false);

  useEffect(() => {
    const fetchEmail = async () => {
      if (open && companyId && !hasFetched.current) {
        hasFetched.current = true; // Mark as fetched
        setIsLoading(true);
        try {
          const response = await api.post(`/api/profiles/${companyId}/cold-email-web`, {
            email: 'michael@mcv.io',
            first_name: 'Michael',
            last_name: 'Bashour',
            title: 'title',
          });
          if (response.data && response.data.message) {
            try {
              const emailData = response.data.message;
              if (emailData.subject && emailData.body) {
                setSubject(emailData.subject);
                setBody(emailData.body);
              } else {
                console.error('Response JSON does not contain required keys.');
              }
            } catch (jsonError) {
              console.error('Error parsing JSON:', jsonError);
            }
          } else {
            console.error('Response does not contain message field:', response.data);
          }
        } catch (error) {
          console.error('Error fetching email:', error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchEmail();
  }, [open, companyId, api]);

  useEffect(() => {
    if (!open) {
      hasFetched.current = false; // Reset the flag when modal is closed
      setSubject(''); // Clear subject when modal is closed
      setBody(''); // Clear body when modal is closed
    }
  }, [open]);

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description">
      <Box sx={style}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography id="modal-title" variant="body2" sx={{ fontWeight: 'bold' }}>
            Email Preview
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box mt={2}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <>
              <TextField
                id="modal-subject"
                label="Subject"
                variant="outlined"
                fullWidth
                value={subject}
                InputProps={{
                  readOnly: true,
                  style: { fontSize: '0.875rem' }, // Smaller font size
                }}
                sx={{ mb: 2 }}
              />
              <Button variant="contained" color="primary" onClick={() => navigator.clipboard.writeText(subject)}>
                Copy Subject
              </Button>
              <TextField
                id="modal-body"
                label="Body"
                multiline
                rows={10}
                variant="outlined"
                fullWidth
                value={body}
                InputProps={{
                  readOnly: true,
                  style: { fontSize: '0.875rem' }, // Smaller font size
                }}
                sx={{ mt: 2 }}
              />
              <Button variant="contained" color="primary" onClick={() => navigator.clipboard.writeText(body)} sx={{ mt: 2 }}>
                Copy Body
              </Button>
            </>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default EmailModal;
