// src/components/NotesTab.js
import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Divider,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Button,
  TextField,
  IconButton,
} from '@mui/material';
import NoteIcon from '@mui/icons-material/Note';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import { format, parseISO } from 'date-fns';
import { useApi } from '../../utilities/api';
import { useOutletContext } from 'react-router-dom';

const NotesTabScreen = () => {
  const { profile } = useOutletContext();
  const api = useApi();
  const [notes, setNotes] = useState([]);
  const [newNote, setNewNote] = useState('');
  const [isPrivate, setIsPrivate] = useState(false);
  const [isAdding, setIsAdding] = useState(false);

  const fetchNotes = async (organizationId) => {
    try {
      const response = await api.get(`/api/profiles/${organizationId}/organization-notes`);
      setNotes(response.data);
    } catch (error) {
      console.error('Error fetching notes:', error);
    }
  };

  const handleAddNote = async () => {
    if (!newNote.trim()) return;

    try {
      const response = await api.post(`/api/profiles/${profile.id}/organization-notes`, {
        note: newNote,
        is_private: isPrivate,
      });
      setNotes([...notes, response.data]);
      setNewNote('');
      setIsPrivate(false);
      setIsAdding(false);
    } catch (error) {
      console.error('Error adding note:', error);
    }
  };

  useEffect(() => {
    if (profile.id) {
      fetchNotes(profile.id);
    }
  }, [profile.id]);

  const renderNoteItem = (note) => {
    const formattedDate = format(parseISO(note.created_at), 'PPP p');
    return (
      <ListItem key={note.id}>
        <ListItemAvatar>
          <Avatar>
            <NoteIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={`${note.user.first_name} ${note.user.last_name}`}
          secondary={`${formattedDate}\n${note.note}`}
        />
      </ListItem>
    );
  };

  return (
    <Card variant="outlined" sx={{ boxShadow: 3, borderRadius: 1 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Notes
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <Box mb={2}>
          <List>
            {notes.length > 0 ? notes.map(renderNoteItem) : (
              <Typography variant="body2" color="textSecondary">
                No notes available for this organization.
              </Typography>
            )}
          </List>
        </Box>
        {isAdding ? (
          <Box display="flex" flexDirection="column" mb={2}>
            <TextField
              value={newNote}
              onChange={(e) => setNewNote(e.target.value)}
              label="New Note"
              fullWidth
              multiline
              rows={2}
            />
            <Box display="flex" justifyContent="space-between" alignItems="center" mt={1}>
              <Button variant="outlined" onClick={() => setIsPrivate(!isPrivate)} color={isPrivate ? 'primary' : 'secondary'}>
                {isPrivate ? 'Private Note' : 'Public Note'}
              </Button>
              <Box>
                <IconButton color="primary" onClick={handleAddNote} sx={{ ml: 1 }}>
                  <AddIcon />
                </IconButton>
                <IconButton color="secondary" onClick={() => setIsAdding(false)} sx={{ ml: 1 }}>
                  <CancelIcon />
                </IconButton>
              </Box>
            </Box>
          </Box>
        ) : (
          <Button variant="contained" onClick={() => setIsAdding(true)} startIcon={<AddIcon />}>
            Add Note
          </Button>
        )}
      </CardContent>
    </Card>
  );
};

export default NotesTabScreen;
