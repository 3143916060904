import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Box,
  IconButton,
  InputBase,
  styled,
  alpha,
  Menu,
  MenuItem,
  Avatar,
  Tooltip,
  Button,
  ListItemIcon,
  Typography,
  Container
} from '@mui/material';
import HomeRounded from '@mui/icons-material/HomeRounded';
import SearchRounded from '@mui/icons-material/SearchRounded';
import StarRounded from '@mui/icons-material/StarRounded';
import AccountCircleRounded from '@mui/icons-material/AccountCircleRounded';
import PortraitRounded from '@mui/icons-material/PortraitRounded';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import LogoutRounded from '@mui/icons-material/LogoutRounded';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useApi } from '../utilities/api';
import '../fonts.css';
import {AutoAwesomeRounded} from "@mui/icons-material";

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.black, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.black, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

const NavButton = styled(Button)(({ theme, active }) => ({
  flexDirection: 'column',
  paddingBottom: 4,
  paddingTop: 8,
  paddingX: 2,
  minWidth: 82,
  color: active ? '#007BFF' : '#686868',
  '& .MuiSvgIcon-root': {
    fontSize: '1.4rem',
  },
  borderBottom: active ? `2px solid #007BFF` : 'none',
  borderTopRightRadius: 2,
  borderTopLeftRadius: 2,
  borderBottomRightRadius: 0,
  borderBottomLeftRadius: 0,
  textTransform: 'none',
}));

const TopBar = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const api = useApi();
  const navigate = useNavigate();
  const location = useLocation();
  const { user, logout } = useAuth0();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const logout_redirect_uri = process.env.REACT_APP_API_LOGOUT_REDIRECT_URL;

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleMenuClose();
    logout({ logoutParams: { returnTo: logout_redirect_uri } });
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      const response = await api.get(`api/name-search?borrowerName=${searchQuery}`);
      navigate('/search-results', { state: { results: response.data } });
    } catch (error) {
      console.error('Error performing search:', error);
    }
  };

  return (
    <AppBar
      position="fixed"
      color="default"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: '#f5f5f5', boxShadow: 1, height: 53 }}
    >
      <Container maxWidth="xl" disableGutters>
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: 53,
            justifyContent: 'space-between',
          }}
          variant='dense'
        >
          <Box display="flex" alignItems="center" sx={{ color: 'black' }}>
            <img
              src="https://loanscout-public.s3.us-west-2.amazonaws.com/LoanScout-7.png"
              alt="LoanScout Logo"
              style={{ height: '36px', marginRight: '20px' }}
            />
          </Box>
          <Box display="flex" alignItems="center">
            <NavButton component={Link} to="/home" active={location.pathname === '/home' ? 'true' : undefined}>
              <HomeRounded />
              <Typography
                variant="caption"
                sx={{ fontFamily: 'Arial, sans-serif', fontWeight: 'normal' }}
              >
                Home
              </Typography>
            </NavButton>
            <NavButton component={Link} to="/search" active={location.pathname === '/search' ? 'true' : undefined}>
              <SearchRounded />
              <Typography
                variant="caption"
                sx={{ fontFamily: 'Arial, sans-serif', fontWeight: 'normal' }}
              >
                Search
              </Typography>
            </NavButton>
            <NavButton component={Link} to="/suggestions" active={location.pathname === '/suggestions' ? 'true' : undefined}>
              <AutoAwesomeRounded />
              <Typography
                variant="caption"
                sx={{ fontFamily: 'Arial, sans-serif', fontWeight: 'normal' }}
              >
                Suggestions
              </Typography>
            </NavButton>
            <NavButton component={Link} to="/following" active={location.pathname === '/following' ? 'true' : undefined}>
              <StarRounded />
              <Typography
                variant="caption"
                sx={{ fontFamily: 'Arial, sans-serif', fontWeight: 'normal' }}
              >
                Following
              </Typography>
            </NavButton>

            <Tooltip title="Account settings">
              <NavButton component={Link} onClick={handleMenuOpen} sx={{ borderLeft: '1px solid #a9a9a9', borderRadius: 0}}>
                <PortraitRounded />
                <Typography variant="caption" sx={{ fontFamily: 'Arial, sans-serif', fontWeight: 'normal', display: 'flex', alignItems: 'center' }}>
                  Me
                  <ArrowDropDown />
                </Typography>
              </NavButton>
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleMenuClose}
              onClick={handleMenuClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem onClick={() => navigate('/account')}>
                <ListItemIcon>
                  <AccountCircleRounded fontSize="small" />
                </ListItemIcon>
                Account
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <LogoutRounded fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default TopBar;
