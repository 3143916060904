import React, { useState, useEffect } from 'react';
import {
  Card, CardContent, Container, Box, Typography, Button, Grid, Paper, Chip, TextField, Tooltip, Select, MenuItem, Link
} from '@mui/material';
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarExport,
  useGridApiRef
} from '@mui/x-data-grid-pro';
import { useApi } from '../utilities/api';
import EventIcon from '@mui/icons-material/Event';
import StarsIcon from "@mui/icons-material/Stars";
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import AssignmentIcon from '@mui/icons-material/Assignment';
import UpdateIcon from '@mui/icons-material/Update';
import CancelIcon from '@mui/icons-material/Cancel';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LoadingOverlay from '../components/LoadingOverlay';
import { useNavigate } from 'react-router-dom';
import { PrettyOrgName, expandRecordCode } from "../utilities/Utilities";
import { format, parse, formatDistanceToNow } from "date-fns";
import EmailModal from '../components/EmailModal'; // Import the new modal component

const FollowedCompaniesPage = () => {
  const api = useApi();
  const navigate = useNavigate();
  const apiRef = useGridApiRef();
  const [isLoading, setIsLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [analytics, setAnalytics] = useState({
    totalFollowed: 0,
    upcomingAnniversaries: 0,
    recentActivity: 0,
  });
  const [emailModalOpen, setEmailModalOpen] = useState(false); // State for modal visibility
  const [selectedCompanyId, setSelectedCompanyId] = useState(null); // State for selected company ID

  const formatDate = (dateString) => dateString ? format(parse(dateString, 'yyyyMMdd', new Date()), 'MMM d, yyyy') : '--';
  const formatEventDate = (dateString) => {
    if (dateString === null) return '--';
    const parsedDate = parse(dateString, 'yyyy-MM-dd', new Date());
    return formatDistanceToNow(parsedDate, 'MMMM d, yyyy', { addSuffix: true });
  };

  const handleCompanyProfile = (companyId) => {
    navigate(`/profile/${companyId}/home`);
  };

  const fetchCompanies = async () => {
    setIsLoading(true);
    try {
      const response = await api.get('api/users/following');
      const sortedCompanies = response.data.sort((a, b) => PrettyOrgName(a).localeCompare(PrettyOrgName(b)));
      const companiesWithStatus = sortedCompanies.map(company => ({
        ...company,
        is_following: true
      }));

      const totalFollowed = companiesWithStatus.length;
      const upcomingAnniversaries = companiesWithStatus.filter(company => company.organization_status.upcoming_anniversary !== null).length;
      const recentActivity = companiesWithStatus.filter(company => company.organization_status.latest_amendment_type).length;

      setCompanies(companiesWithStatus);
      setFilteredCompanies(companiesWithStatus);
      setAnalytics({
        totalFollowed,
        upcomingAnniversaries,
        recentActivity,
      });
    } catch (error) {
      console.error('Error fetching companies:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleFollow = async (company) => {
    try {
      const url = `/api/profiles/${company.id}/follow`;
      const method = company.is_following ? 'delete' : 'post';

      const updatedCompanies = companies.map((item) =>
        item.id === company.id ? { ...item, is_following: !item.is_following } : item
      );
      setCompanies(updatedCompanies);
      setFilteredCompanies(updatedCompanies);

      await api[method](url);
    } catch (error) {
      console.error('Error changing follow status:', error);
      setCompanies((prevCompanies) =>
        prevCompanies.map((item) =>
          item.id === company.id ? { ...item, is_following: !item.is_following } : item
        )
      );
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchText(value);

    const filtered = companies.filter(company =>
      PrettyOrgName(company).toLowerCase().includes(value)
    );

    setFilteredCompanies(filtered);
  };

  const handleEmailButtonClick = (companyId) => {
    setSelectedCompanyId(companyId);
    setEmailModalOpen(true);
  };

  const handleZapierButtonClick = async (companyId) => {
    console.log('pushing to zapier');
    const response = await api.get(`/api/profiles/${companyId}/push-to-zapier`);
    console.log(response.data);
    return response.data
  };

  const handleCloseEmailModal = () => {
    setEmailModalOpen(false);
    setSelectedCompanyId(null);
  };

  const handleFollowTypeChange = async (companyId, followType) => {
    try {
      await api.patch(`/api/profiles/${companyId}/follow-type`, { follow_type: followType });
      // Use apiRef to directly update the row
      apiRef.current.updateRows([{ id: companyId, follow: { follow_type: followType } }]);
    } catch (error) {
      console.error('Error changing follow type:', error);
    }
  };

  useEffect(() => {
    fetchCompanies();
  }, []);

  const getFilingTypeChip = (filingType) => {
    switch (filingType) {
      case 'MR':
        return <Chip icon={<AssignmentIcon />} label="Master Record" variant="outlined" size="small" sx={{ width: '140px', px: 1, fontWeight: 'bold', fontSize: '0.75rem' }} />;
      case 'C':
        return <Chip icon={<UpdateIcon />} label="Continuation" variant="outlined" size="small" sx={{ width: '140px', px: 1, fontWeight: 'bold', fontSize: '0.75rem' }} />;
      case 'T':
        return <Chip icon={<CancelIcon />} label="Termination" variant="outlined" size="small" sx={{ width: '140px', px: 1, fontWeight: 'bold', fontSize: '0.75rem' }} />;
      default:
        return <Chip icon={<HelpOutlineIcon />} label="Other" variant="outlined" size="small" sx={{ width: '140px', px: 1, fontWeight: 'bold', fontSize: '0.75rem' }} />;
    }
  };

  const CustomGridToolbar = () => {
    return (
      <GridToolbarContainer>
        <Box sx={{ flexGrow: 1 }} />
        <GridToolbarExport
          slotProps={{
            tooltip: { title: 'Export data' },
          }}
        />
      </GridToolbarContainer>
    );
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      align: 'left',
      headerAlign: 'center',
      width: 340,
      renderCell: (params) => (
        <Box
          display="flex"
          alignItems="center"
          height="100%"
          width="100%"
          sx={{ cursor: 'pointer', color: '#007bff', px: 2 }}
          onClick={() => handleCompanyProfile(params.row.id)}
        >
          <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '0.75rem' }}>
            {PrettyOrgName(params.row)}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'follow_type',
      headerName: 'Status',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      sortComparator: (v1, v2, param1, param2) => {
        const a = param1.api.getRow(param1.id).follow.follow_type;
        const b = param2.api.getRow(param2.id).follow.follow_type;
        return a.localeCompare(b);
      },
      renderCell: (params) => (
        <Select
          value={params.row.follow.follow_type}
          onChange={(e) => handleFollowTypeChange(params.row.id, e.target.value)}
          sx={{
            '& .MuiSelect-select': {
              display: 'flex',
              alignItems: 'center',
              padding: 0,
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '.MuiDataGrid-root .MuiDataGrid-cell': {
              textAlign: 'center',
              justifyContent: 'center',
            },
          }}
        >
          <MenuItem value="basic">
            <Chip label="Followed" variant="outlined" color="default" />
          </MenuItem>
          <MenuItem value="customer">
            <Chip label="Customer" variant="outlined" color="primary" />
          </MenuItem>
          <MenuItem value="prospect">
            <Chip label="Prospect" variant="outlined" color="secondary" />
          </MenuItem>
        </Select>
      ),
    },
    {
      field: 'industry',
      headerName: 'Industry',
      width: 280,
      align: 'center',
      headerAlign: 'center',
      sortComparator: (v1, v2, param1, param2) => {
        const a = param1.api.getRow(param1.id).industry;
        const b = param2.api.getRow(param2.id).industry;
        return a.localeCompare(b);
      },
      renderCell: (params) => {
        const industry = params.row.industry;
        return (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            height="100%"
            width="100%"
            sx={{ cursor: 'pointer', px: 2 }}
          >
            <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '0.75rem', textTransform: 'titleCase' }}>
              {industry !== null ? industry : '--'}
            </Typography>
          </Box>
        )
      }
    },
    {
      field: 'location_city',
      headerName: 'City',
      width: 100,
      align: 'center',
      headerAlign: 'center',
      sortComparator: (v1, v2, param1, param2) => {
        const a = param1.api.getRow(param1.id).location.city;
        const b = param2.api.getRow(param2.id).location.city;
        return a.localeCompare(b);
      },
      renderCell: (params) => {
        const companyLocation = params.row.location;
        return (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            height="100%"
            width="100%"
            sx={{ cursor: 'pointer', px: 2 }}
          >
            <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '0.75rem', textTransform: 'titleCase' }}>
              {companyLocation.city !== null ? companyLocation.city : '--'}
            </Typography>
          </Box>
        );
      }
    },
    {
      field: 'location_state',
      headerName: 'State',
      width: 100,
      align: 'center',
      headerAlign: 'center',
      sortComparator: (v1, v2, param1, param2) => {
        const a = param1.api.getRow(param1.id).location.state;
        const b = param2.api.getRow(param2.id).location.state;
        return a.localeCompare(b);
      },
      renderCell: (params) => {
        const companyLocation = params.row.location;
        return (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            height="100%"
            width="100%"
            sx={{ cursor: 'pointer', px: 2 }}
          >
            <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '0.75rem' }}>
              {companyLocation.state !== null ? companyLocation.state : '--'}
            </Typography>
          </Box>
        );
      }
    },
    {
      field: 'website',
      headerName: 'Website',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        const website = params.row.website;
        return (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            height="100%"
            width="100%"
            sx={{ cursor: 'pointer', px: 2 }}
          >
            {website ? (
              <Link href={`https://${website}`} target="_blank" rel="noopener noreferrer" underline="hover" sx={{ fontWeight: 'bold', fontSize: '0.75rem' }}>
                {website}
              </Link>
            ) : (
              <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '0.75rem' }}>
                --
              </Typography>
            )}
          </Box>
        );
      }
    },
    {
      field: 'loan_count',
      headerName: 'Total Loans',
      width: 120,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        const loanCount = params.row.loan_count;
        return (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            height="100%"
            width="100%"
            sx={{ cursor: 'pointer', px: 2 }}
          >
            <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '0.75rem' }}>
              {loanCount}
            </Typography>
          </Box>
        );
      }
    },
    {
      field: 'upcoming_anniversary_filing_type',
      headerName: 'Upcoming Event Type',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      sortComparator: (v1, v2, param1, param2) => {
        const customOrder = ['T', 4, 3, 2, 1];

        const a = param1.api.getRow(param1.id).upcoming_anniversary?.filing_type;
        const b = param2.api.getRow(param2.id).upcoming_anniversary?.filing_type;
        // Handle undefined values: assign them a position at the end of the custom order
        const indexA = a === undefined ? customOrder.length : customOrder.indexOf(a);
        const indexB = b === undefined ? customOrder.length : customOrder.indexOf(b);

        return indexA - indexB;
      },
      renderCell: (params) => {
        const nextEvent = params.row.upcoming_anniversary;
        return (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            height="100%"
            width="100%"
            sx={{cursor: 'pointer', px: 2}}
          >
            {nextEvent ? (
              <Chip
                icon={nextEvent !== null ? <EventIcon/> : <CancelIcon/>}
                label={
                  nextEvent.filing_type === "termination" ? "Termination" :
                    nextEvent.filing_type !== null ? `${nextEvent.filing_type}-year Anniversary` : 'None'
                }
                variant="outlined"
                size="small"
                sx={{width: '140px', px: 1, fontWeight: 'bold', fontSize: '0.75rem'}}
              />
            ) : (
              <Typography variant="body2" sx={{fontWeight: 'bold', fontSize: '0.75rem'}}>
                --
              </Typography>
            )}
          </Box>
        )
      }
    },
    {
      field: 'upcoming_anniversary_date',
      headerName: 'Upcoming Event Date',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      sortComparator: (v1, v2, param1, param2) => {
        const a = param1.api.getRow(param1.id).upcoming_anniversary?.next_anniversary || '';
        const b = param1.api.getRow(param2.id).upcoming_anniversary?.next_anniversary || '';
        return a.localeCompare(b);
      },
      renderCell: (params) => {
        const nextEvent = params.row.upcoming_anniversary;
        return(
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            height="100%"
            width="100%"
            sx={{ cursor: 'pointer', px: 2 }}
          >
            {nextEvent ? (
              <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '0.75rem' }}>
                In {formatEventDate(nextEvent.next_anniversary)}
              </Typography>
            ) : (
              <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '0.75rem' }}>
                --
              </Typography>
            )}
          </Box>
        )
      }
    },
    {
      field: 'upcoming_anniversary_lender',
      headerName: 'Upcoming Event Lender',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        const nextEvent = params.row.upcoming_anniversary;

        return (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            height="100%"
            width="100%"
            sx={{ cursor: 'pointer', px: 2 }}
          >
            {nextEvent ? (
              <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '0.75rem' }}>
                {PrettyOrgName(nextEvent)}
              </Typography>
            ) : (
              <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '0.75rem' }}>
                --
              </Typography>
            )}
          </Box>
        );
      }
    },
    {
      field: 'latest_amendment_type_filing',
      headerName: 'Latest Filing Type',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      sortComparator: (v1, v2, param1, param2) => {
        const a = param1.api.getRow(param1.id).latest_amendment_type.filing_type;
        const b = param2.api.getRow(param2.id).latest_amendment_type.filing_type;
        return a.localeCompare(b);
      },
      renderCell: (params) => {
        const latestAmendment = params.row.latest_amendment_type;
        return (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            height="100%"
            width="100%"
            sx={{ cursor: 'pointer', px: 2 }}
          >
            { latestAmendment ? (
                getFilingTypeChip(latestAmendment.filing_type)
              ) : (
                <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '0.75rem' }}>No recent activity</Typography>
              )
            }
          </Box>
        );
      },
    },
    {
      field: 'latest_amendment_date_filing',
      headerName: 'Latest Filing Date',
      width: 160,
      align: 'center',
      headerAlign: 'center',
      sortComparator: (v1, v2, param1, param2) => {
        const a = param1.api.getRow(param1.id).latest_amendment_type.filing_date;
        const b = param1.api.getRow(param2.id).latest_amendment_type.filing_date;
        return a - b;
      },
      renderCell: (params) => {
        const latestAmendment = params.row.latest_amendment_type;

        return (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            height="100%"
            width="100%"
            sx={{ cursor: 'pointer', px: 2 }}
          >
            { latestAmendment ? (
                <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '0.75rem' }}>{formatDate(latestAmendment.filing_date)}</Typography>
              ) : (
                <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '0.75rem' }}>No recent activity</Typography>
              )
            }
          </Box>
        );
      },
    },
    {
      field: 'latest_amendment_type_lender',
      headerName: 'Latest Filing Lender',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      sortComparator: (v1, v2, param1, param2) => {
        const a = PrettyOrgName(param1.api.getRow(param1.id).latest_amendment_type)
        const b = PrettyOrgName(param1.api.getRow(param2.id).latest_amendment_type);
        return a.localeCompare(b);
      },
      renderCell: (params) => {
        const latestAmendment = params.row.latest_amendment_type;
        return (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            height="100%"
            width="100%"
            sx={{cursor: 'pointer', px: 2}}
          >
            { latestAmendment ? (
              <Typography variant="body2" sx={{fontWeight: 'bold', fontSize: '0.75rem'}}>{ PrettyOrgName(latestAmendment) }</Typography>
              ) : (
              <Typography variant="body2" sx={{fontWeight: 'bold', fontSize: '0.75rem'}}>No recent activity</Typography>
              )
            }
          </Box>
        );
      }
    },
    {
      field: 'analytics',
      headerName: '',
      width: 160,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: (params) => {
        const borrower_analytics = params.row.borrower_analytics;
        return (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="row"
            height="100%"
            width="100%"
          >
            {borrower_analytics?.loyal_borrower && (
              <Tooltip title="Loyal Borrower">
                <StarsIcon sx={{ fontSize: 20, color: 'gray', mx: 0.5 }} />
              </Tooltip>
            )}
            {borrower_analytics?.frequent_borrower && (
              <Tooltip title="Frequent Borrower">
                <EventRepeatIcon sx={{ fontSize: 20, color: 'gray', mx: 0.5 }} />
              </Tooltip>
            )}
          </Box>
        );
      }
    },
    {
      field: 'is_following',
      headerName: 'Follow Status',
      width: 160,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <Button
            variant="outlined"
            size="small"
            color={params.value ? 'primary' : 'secondary'}
            sx={{ fontWeight: 'bold', fontSize: '0.75rem', textTransform: 'none' }}
            onClick={(e) => {
              e.stopPropagation();
              toggleFollow(params.row);
            }}
          >
            {params.value ? 'Following' : 'Follow'}
          </Button>
        );
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 160,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: (params) => (
        <Button
          variant="outlined"
          size="small"
          onClick={() => handleEmailButtonClick(params.row.id)}
          sx={{ fontWeight: 'bold', fontSize: '0.75rem', textTransform: 'none' }}
        >
          Generate Email
        </Button>
      ),
    },
    // {
    //   field: 'crm',
    //   headerName: 'CRM',
    //   width: 160,
    //   align: 'center',
    //   headerAlign: 'center',
    //   sortable: false,
    //   renderCell: (params) => (
    //     <Button
    //       variant="outlined"
    //       size="small"
    //       onClick={() => handleZapierButtonClick(params.row.id)}
    //       sx={{ fontWeight: 'bold', fontSize: '0.75rem', textTransform: 'none' }}
    //     >
    //       Push to CRM
    //     </Button>
    //   ),
    // },
  ];

  const rows = filteredCompanies.map((company) => ({
    id: company.id,
    industry: company.industry,
    location: company.location,
    state: company.state,
    website: company.domain,
    name: PrettyOrgName(company),
    loan_count: company.loan_count,
    latest_amendment_type: company.organization_status.latest_amendment_type,
    upcoming_anniversary: company.organization_status.upcoming_anniversary,
    borrower_analytics: company.organization_status.borrower_analytics,
    is_following: company.is_following,
    follow: company.follow,
    logo_url: company.logo_url,
  }));

  return (
    <Container maxWidth={'lg'}>
      <Box position="relative">
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Box mb={2}>
                  <Typography variant="h6">Followed Companies</Typography>
                </Box>
                <Box mb={2}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Search Companies"
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={searchText}
                        onChange={handleSearch}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Box border={1} borderColor="grey.400" borderRadius={1} p={1} textAlign="center">
                        <Typography variant="subtitle2" sx={{ fontWeight: 'bold', fontSize: '0.75rem' }}>
                          {analytics.totalFollowed} Companies Followed
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Box border={1} borderColor="grey.400" borderRadius={1} p={1} textAlign="center">
                        <Typography variant="subtitle2" sx={{ fontWeight: 'bold', fontSize: '0.75rem' }}>
                          {analytics.upcomingAnniversaries} Upcoming Events
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Button variant="contained" color="primary" fullWidth onClick={() => navigate('/search')} sx={{ textTransform: 'none' }}>
                        Find More
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={{ width: '100%', overflowX: 'scroll' }}>
                  <Paper style={{ width: '100%' }}>
                    <DataGridPro
                      autoHeight
                      pagination
                      pageSizeOptions={[25, 50, 100]}
                      rows={rows}
                      columns={columns}
                      loading={isLoading}
                      checkboxSelection
                      disableCheckboxSelection
                      sx={{
                        overflowX: 'scroll',
                        maxWidth: '100%',
                        '.MuiDataGrid-cell': { paddingX: 0 },
                        '.MuiDataGrid-columnHeaderTitle': { fontWeight: 'bold', fontSize: '0.75rem' },
                        '& .MuiDataGrid-columnHeaders': {
                          borderBottom: '1px solid #007bff',
                        },
                        '& .MuiDataGrid-columnHeader': {
                          backgroundColor: '#f4f4f4',
                          borderBottom: '1px solid #007bff',
                        },
                      }}
                      disableRowSelectionOnClick
                      initialState={{
                        pinnedColumns: { left: ['name'] },
                        density: 'standard'
                      }}
                    />
                  </Paper>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <EmailModal open={emailModalOpen} handleClose={handleCloseEmailModal} companyId={selectedCompanyId} />
    </Container>
  );
};

export default FollowedCompaniesPage;
