// Search.js
import React, { useState, useEffect } from 'react';
import { Card, CardContent, Container, TextField, Button, Typography, Box, Grid } from '@mui/material';
import Select from 'react-select';
import { useApi } from '../utilities/api';
import { useNavigate, useLocation } from 'react-router-dom';
import naicsOptions from '../utilities/NaicsCodes';
import SearchResults from "../components/SearchResults";

const SearchPage = () => {
  const api = useApi();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchFields, setSearchFields] = useState({
    borrowerName: '',
    borrowerCity: '',
    borrowerState: '',
    borrowerZip: '',
    borrowerNAICS: '',
    borrowerIndustry: '',
    lenderName: '',
    lenderCity: '',
    lenderState: '',
    lenderZip: '',
    collateralDescription: '',
    expiringSoon: false,
  });
  const [selectedNaics, setSelectedNaics] = useState([]);
  const [results, setResults] = useState(location.state?.results || []);
  const [isLoading, setIsLoading] = useState(false);
  const [paginationModel, setPaginationModel] = useState(location.state?.paginationModel || { page: 0, pageSize: 25 });
  const [rowCount, setRowCount] = useState(location.state?.rowCount || 0);
  const [sortingModel, setSortingModel] = useState(location.state?.sortingModel || [{ field: 'name', sort: 'asc' }]);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const naics = naicsOptions.map(option => ({ value: option.code, label: `${option.code} - ${option.title}` }));

  useEffect(() => {
    if (location.state) {
      setSearchFields(location.state.searchFields || {});
      setSelectedNaics(location.state.selectedNaics || []);
      setResults(location.state.results || []);
      setRowCount(location.state.rowCount || 0);
      // setPaginationModel(location.state.paginationModel || { page: 0, pageSize: 10 });
      // setSortingModel(location.state.sortingModel || [{ field: 'name', sort: 'asc' }]);
    }
  }, [location.state]);

  const handleFieldChange = (field, value) => {
    setSearchFields((prev) => ({ ...prev, [field]: value }));
  };

  const handleSelectNaics = (selectedOptions) => {
    const selectedValues = selectedOptions.map(option => option.value);
    setSelectedNaics(selectedValues);
    setSearchFields((prev) => ({ ...prev, borrowerNAICS: selectedValues.join(',') }));
  };

  const fetchResults = async (model = paginationModel) => {
    setIsLoading(true);
    try {
      const params = new URLSearchParams({
        ...searchFields,
        page: model.page + 1, // DRF pagination is 1-based, MUI DataGrid is 0-based
        pageSize: model.pageSize,
        sortField: sortingModel[0]?.field || 'name',
        sortOrder: sortingModel[0]?.sort || 'asc'
      }).toString();
      const response = await api.get(`api/simple-search?${params}`);
      setResults(response.data.results);
      setRowCount(response.data.count);
      // Save search state to location
      navigate('.', {
        state: {
          searchFields,
          selectedNaics,
          results: response.data.results,
          rowCount: response.data.count,
          paginationModel: model,
          sortingModel
        }
      });
    } catch (error) {
      console.error('Error submitting search form:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (event, newSearch) => {
    event.preventDefault();
    if (newSearch) {
      const initialModel = { page: 0, pageSize: paginationModel.pageSize };
      setSortingModel([{ field: 'name', sort: 'asc' }]);
      setPaginationModel(initialModel); // Reset to first page on new search
    }
    await fetchResults();
  };

  useEffect(() => {
    if (isInitialLoad && location.state) {
      setIsInitialLoad(false); // Prevent initial fetch on page load if there is state
    } else if (!isInitialLoad) {
      fetchResults(paginationModel); // Fetch results on pagination or sorting model change
    }
  }, [paginationModel, sortingModel]);

  const handleReset = () => {
    setSearchFields({
      borrowerName: '',
      borrowerCity: '',
      borrowerState: '',
      borrowerZip: '',
      borrowerNAICS: '',
      borrowerIndustry: '',
      lenderName: '',
      lenderCity: '',
      lenderState: '',
      lenderZip: '',
      collateralDescription: '',
      expiringSoon: false,
    });
    setSelectedNaics([]);
    setResults([]);
    // setPaginationModel({ page: 0, pageSize: 10 });
    // setSortingModel([{ field: 'name', sort: 'asc' }]);
    localStorage.removeItem('searchState');
  };

  return (
    <Container maxWidth={'xl'}>
      <Box position="relative">
        <Grid container spacing={0}>
          {/* Sidebar Search Form */}
          <Grid item xs={3} md={2}>
            <Card>
              <CardContent>
                <form onSubmit={handleSubmit}>
                  <Typography variant="body2" sx={{ mt: 0, fontWeight: 'bold', fontSize: '0.75rem' }}>Borrower</Typography>
                  {['Name', 'City', 'State', 'Zip', 'Industry'].map((field) => (
                    <TextField
                      key={`Borrower${field}`}
                      label={`Borrower ${field}`}
                      value={searchFields[`borrower${field}`]}
                      onChange={(e) => handleFieldChange(`borrower${field}`, e.target.value)}
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      size="small"
                      InputProps={{ style: { fontSize: '0.85rem' } }}
                      InputLabelProps={{ style: { fontSize: '0.85rem' } }}
                    />
                  ))}
                  {/*<Typography variant="body2" sx={{ mt: 1, fontWeight: 'bold', fontSize: '0.75rem' }}>NAICS Codes</Typography>*/}
                  {/*<Select*/}
                  {/*  isMulti*/}
                  {/*  options={naics}*/}
                  {/*  value={selectedNaics.map(code => naics.find(option => option.value === code))}*/}
                  {/*  onChange={handleSelectNaics}*/}
                  {/*  styles={{ container: (base) => ({ ...base, marginBottom: 16, zIndex: 999 }), input: (base) => ({ ...base, fontSize: '0.75rem' }) }}*/}
                  {/*/>*/}
                  <Typography variant="body2" sx={{ mt: 0, fontWeight: 'bold', fontSize: '0.75rem' }}>Lender Information</Typography>
                  {['Name', 'City', 'State', 'Zip'].map((field) => (
                    <TextField
                      key={`Lender${field}`}
                      label={`Lender ${field}`}
                      value={searchFields[`lender${field}`]}
                      onChange={(e) => handleFieldChange(`lender${field}`, e.target.value)}
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      size="small"
                      InputProps={{ style: { fontSize: '0.85rem' } }}
                      InputLabelProps={{ style: { fontSize: '0.85rem' } }}
                    />
                  ))}
                  <Typography variant="body2" sx={{ mt: 1, fontWeight: 'bold', fontSize: '0.75rem' }}>Loan Information</Typography>
                  <TextField
                    label="Collateral Description"
                    value={searchFields.collateralDescription}
                    onChange={(e) => handleFieldChange('collateralDescription', e.target.value)}
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    size="small"
                    InputProps={{ style: { fontSize: '0.85rem' } }}
                    InputLabelProps={{ style: { fontSize: '0.85rem' } }}
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={(e) => handleSubmit( e, true)}
                    disabled={isLoading}
                    sx={{ mt: 2, textTransform: 'none' }}
                  >
                    Search
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={handleReset}
                    sx={{ mt: 2, mb: 0, textTransform: 'none' }}
                  >
                    Clear
                  </Button>
                </form>
              </CardContent>
            </Card>
          </Grid>

          {/* Search Results */}
          <Grid item xs={12} md={10}>
            <Box sx={{ width: '100%', overflowX: 'auto' }}>
              <SearchResults
                results={results}
                setResults={setResults}
                paginationModel={paginationModel}
                setPaginationModel={setPaginationModel}
                rowCount={rowCount}
                sortingModel={sortingModel}
                setSortingModel={setSortingModel}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default SearchPage;
